import { Link, useParams } from "react-router-dom";
import addcart from "../../images/icons/addcart.png";
import wishListIcon from "../../images/icons/wishlist.png";
import activeWishListIcon from "../../images/icons/inWishList.png";
import Accordion from "react-bootstrap/Accordion";
import { useEffect, useState } from "react";
import { Global } from "../../Variables/Global";
import axios from "axios";
import { FaList } from "react-icons/fa";
import { BsGrid3X3GapFill, BsGridFill } from "react-icons/bs";
import { BiFilterAlt } from "react-icons/bi";
import { FiChevronLeft, FiX } from "react-icons/fi";

const ListProducts = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [priceInterval, setPriceInterval] = useState([]);
  const [filters, setFilters] = useState([
    {
      filterName: "",
      filterValues: [],
    },
  ]);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState([]);
  const [selectedAttribute, setSelectedAttributes] = useState([]);
  const [selectedAllAttributes, setSelectedAllAttributes] = useState([]);
  const [prices, setPrices] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState([]);
  const params = useParams();
  let LIMIT = 15;

  function getIntervals(min, max, nbIntervalls) {
    max -= min;
    var size = Math.round((max - 1) / nbIntervalls);
    var result = [];

    for (let i = 0; i < nbIntervalls; i++) {
      var inf = i + i * size;
      var sup = inf + size < max ? inf + size : max;

      result.push([inf + min, sup + min]);
      if (inf >= max || sup >= max) break;
    }
    return result;
  }

  const getFilters = async (index) => {
    setIsLoading(true);
    var groupId;
    if (localStorage.getItem("WishList")) {
      groupId = 4;
    } else {
      groupId = 3;
    }
    fetch(Global.API_URL + "AttributeValues/GetFiltersByCategory", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
      body: JSON.stringify({
        categoryId: params.CategoryId,
        pageId: index,
        count: LIMIT,
        countryId: 125,
        groupId: groupId,
        attributes: [],
        categories: [params.CategoryId],
        offset: -150,
        prices: [],
      }),
    })
      .then((Response) => {
        console.log(Response)
        if (Response.ok) {
          return Response.json();
        }
      })
      .then((Result) => {
        console.log(Result)
        if (Result != undefined) {
          var array = [
            {
              filterName: "",
              filterValues: [],
            },
          ];
          Result.Attributes.forEach((filter) => {
            array.push({
              filterName: filter.AttributeName,
              filterValues: filter.AttributeValues,
            });
          });
          array.push({
            filterName: "Category",
            filterValues: Result.Categories,
          });
          array = array.filter((a) => a.filterName !== "");
          let intervals = getIntervals(
            Result.Prices.MinimumPrice,
            Result.Prices.MaximumPrice,
            5
          );
          array.push({
            filterName: "Price",
            filterValues: intervals,
          });
          setPriceInterval(intervals);
          setFilters(array);
        }
      });
  };

  const getProductByCategoryId = async (index) => {
    var groupId;
    if (localStorage.getItem("WishList")) {
      groupId = 4;
    } else {
      groupId = 3;
    }
    fetch(Global.API_URL + "Products/GetProductsByCategoryId", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
      body: JSON.stringify({
        categoryId: params.CategoryId,
        pageId: index,
        count: LIMIT,
        offset: -150,
        attributes: [],
        categories: [],
        prices: [],
      }),
    })
      .then((Response) => {
        if (Response.ok) return Response.json();
        else throw Error("Did Not Receive expected data");
      })
      .then((Result) => {
        console.log(Result)
        setCategoryProducts(Result);
      });
  };

  const fetchFilteredProductCount = () => {
    fetch(Global.API_URL + "Products/GetProductsCountByCategory", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        categoryId: params.CategoryId,
        attributes: [],
        categories: [],
        prices: [],
      }),
    })
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
      })
      .then((Result) => {
        var array = [],
          total = Result / LIMIT;

        for (var i = 0; i < total; i++) {
          array.push({
            index: i,
            pageNumber: i + 1,
          });
        }
        setTotalPage(array);
      });
    setIsLoading(false);
  };

  const pageChange = (index) => {
    setPage(index);
    getProductByCategoryId(index);
  };

  const FilterProduct = async (e) => {
    const offset = new Date().getTimezoneOffset();
    var ColorArray = [...selectedColors];
    var CategoryArray = [...selectedCategory];
    var intervalprice = [...selectedPrice];
    var attributeArray = [...selectedAttribute];

    if (e.target.name === "color") {
      if (e.target.checked) {
        ColorArray.push(e.target.value);
        setSelectedColors(ColorArray);
      } else {
        var index = ColorArray.indexOf(e.target.value);
        if (index !== -1) {
          ColorArray.splice(index, 1);
          setSelectedColors(ColorArray);
        }
      }
    } else if (e.target.name === "category") {
      if (e.target.checked) {
        CategoryArray.push(e.target.value);
        setSelectedCategory(CategoryArray);
      } else {
        var index = CategoryArray.indexOf(e.target.value);
        if (index !== -1) {
          CategoryArray.splice(index, 1);
          setSelectedCategory(CategoryArray);
        }
      }
    } else if (e.target.name === "price") {
      if (e.target.checked) {
        intervalprice.push(e.target.value);
        setSelectedPrice(intervalprice);
      } else {
        var index = intervalprice.indexOf(e.target.value);
        if (index !== -1) {
          intervalprice.splice(index, 1);
          setSelectedPrice(intervalprice);
        }
      }
    } else {
      if (e.target.checked) {
        attributeArray.push(e.target.value);
        setSelectedAttributes(attributeArray);
      } else {
        var index = attributeArray.indexOf(e.target.value);
        if (index !== -1) {
          attributeArray.splice(index, 1);
          setSelectedAttributes(attributeArray);
        }
      }
    }
    var a = [];
    var priceArray = [];
    intervalprice.map((i) => {
      a.push(i.split(","));
    });
    a.map((m) => {
      priceArray.push({
        startAmount: m[0],
        endAmount: m[1],
      });
    });
    var attribute = [];
    if (ColorArray.length > 0) {
      ColorArray.forEach((c) => {
        attribute.push(c);
      });
    }
    if (attributeArray.length > 0) {
      attributeArray.forEach((c) => {
        attribute.push(c);
      });
    }
    setSelectedAllAttributes(attribute);
    setPrices(priceArray);
    setCategoryProducts([]);

    var groupId;
    if (localStorage.getItem("WishList")) {
      groupId = 4;
    } else {
      groupId = 3;
    }
    var index = 0;
    setPage(index);

    fetch(Global.API_URL + "Products/GetProductsByCategoryId", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
      body: JSON.stringify({
        categoryId: params.CategoryId,
        pageId: page,
        count: LIMIT,
        attributes: attribute,
        categories: CategoryArray,
        prices: priceArray,
        offset: offset,
        countryId: 125,
        groupId: groupId,
      }),
    })
      .then((Response) => Response.json())
      .then((Result) => {
        var newProductArray = [];

        newProductArray = Result;
        fetchFilteredProductCount(attribute, CategoryArray, priceArray);
        setCategoryProducts(newProductArray);
      });
  };

  function openSortList() {
    const box = document.getElementById("SortBox");
    box.classList.add("sortListBoxShow");
  }

  function closeSortList() {
    const box = document.getElementById("SortBox");
    box.classList.remove("sortListBoxShow");
  }

  function openFilterAside() {
    const box = document.getElementById("filterAside");
    box.classList.add("filterAsideBoxShow");
  }

  function closeFilterAside() {
    const box = document.getElementById("filterAside");
    box.classList.remove("filterAsideBoxShow");
  }

  useEffect(() => {
    props.setHeaderTitle(params.CategoryName);
    props.setFooterActive(2);
    window.scrollTo(0, 0);
    getFilters(0);
    getProductByCategoryId();
    fetchFilteredProductCount();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <div className="container productsList">
          <div className="row d-none d-md-block">
            <div className="col-12 col-md-12">
              <ul className="bread-crumb">
                <li>Home</li>
                <li>/</li>
                <li>{params.CategoryName}</li>
              </ul>
            </div>
          </div>
          <div className="row d-none d-md-block">
            <div className="col-12 col-md-12">
              <h1>{params.CategoryName}</h1>
            </div>
          </div>
          {categoryProducts.length > 0 && (
            <>
              <div className="row topFilterLine d-none d-md-flex">
                <div className="col-2">
                  <label className="filterTitle">Filters</label>
                </div>
                <div className="col-5">
                  <span className="noProducts">{categoryProducts.length} Products</span>
                  <span className="sortBy">
                    <strong>Sort: </strong>
                    <select>
                      <option>Relevence</option>
                    </select>
                  </span>
                </div>
                <div className="col-5 listBy text-end">
                  {/* <FaList />
                  <BsGridFill />
                  <BsGrid3X3GapFill /> */}
                </div>
              </div>
              <div className="row mobileFilters d-md-none mb-3">
                <div className="col-8">
                  <span className="Filtername" onClick={openSortList}>
                    <span className="red">Sort: </span>by Relevance
                  </span>
                  <span className="Filtername ms-3" onClick={openFilterAside}>
                    <span className="red">Filter</span> <BiFilterAlt />
                  </span>
                </div>
                {/* <div className="col-4 listByMobile text-end">
                  <FaList />
                  <span className="red">
                    <BsGridFill />
                  </span>
                  <BsGrid3X3GapFill />
                </div> */}
              </div>
              <div className="row">
                <div className="col-12 col-md-3 filterBox d-none d-md-block">
                  {filters.length > 0 && (
                    <Accordion defaultActiveKey="0">
                      {filters.map((option, index) => {
                        return (
                          <Accordion.Item eventKey={index}>
                            {option.filterValues.length > 0 && (
                              <Accordion.Header>
                                {option.filterName}{" "}
                              </Accordion.Header>
                            )}
                            {option.filterName === "Category" ? (
                              <Accordion.Body>
                                <ul>
                                  {option.filterValues.length > 0 &&
                                    option.filterValues.map((c) => {
                                      return (
                                        <li>
                                          <input
                                            type="checkbox"
                                            value={c.CategoryId}
                                            name="category"
                                            onClick={FilterProduct}
                                            className="d-none"
                                            id="categoryFilter"
                                          />{" "}
                                          <label for="categoryFilter">
                                            {c.CategoryName}
                                          </label>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </Accordion.Body>
                            ) : option.filterName === "Color" ? (
                              <Accordion.Body>
                                <ul>
                                  {option.filterValues.length > 0 &&
                                    option.filterValues.map((color) => {
                                      return (
                                        <li key={color.Color}>
                                          <div>
                                            <input
                                              className="plCheckbox me-2"
                                              type="checkbox"
                                              value={color.AttributeValueId}
                                              name="color"
                                              onClick={FilterProduct}
                                              style={{
                                                backgroundColor:
                                                  color.AttributeValue,
                                              }}
                                            />{" "}
                                            {color.AttributeName}
                                          </div>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </Accordion.Body>
                            ) : option.filterName === "Price" ? (
                              <Accordion.Body>
                                <ul>
                                  {priceInterval.map((interval) => {
                                    return (
                                      <>
                                        <li>
                                          <input
                                            type="checkbox"
                                            name="price"
                                            value={interval}
                                            onClick={FilterProduct}
                                            className="d-none"
                                            id="priceFilter"
                                          />
                                          <label for="priceFilter">
                                            ${interval[0]} - ${interval[1]}
                                          </label>
                                        </li>
                                      </>
                                    );
                                  })}
                                </ul>
                              </Accordion.Body>
                            ) : null}
                          </Accordion.Item>
                        );
                      })}
                    </Accordion>
                  )}
                </div>
                <div className="col-12 col-md-9">
                  <div className="row">
                    {categoryProducts.map((product) => {
                      return (
                        <div
                          className="col-6 col-md-4 mb-3"
                          key={product.ProductId}
                        >
                          <div className="item">
                            <div className="imgBox">
                              <Link
                                to={
                                  "/DetailedProduct/" +
                                  product.ProductName.replace(/ /g, "-") +
                                  "/" +
                                  product.ProductId
                                }
                              >
                                <img
                                  src={
                                    Global.PHOTO_URL +
                                    product.DefaultVariation.Image1
                                  }
                                  alt={product.ProductName}
                                />
                              </Link>
                            </div>
                            <div className="content">
                              <button className="btnWishList">
                                <img src={wishListIcon} alt="WishList"></img>
                                <img
                                  src={activeWishListIcon}
                                  alt="In WishList"
                                  style={{ display: "none" }}
                                ></img>
                              </button>
                              <Link
                                to={
                                  "/DetailedProduct/" +
                                  product.ProductName.replace(/ /g, "-") +
                                  "/" +
                                  product.ProductId
                                }
                              >
                                <h5>{product.ProductName}</h5>
                              </Link>
                              <p className="sub">Playmegastore</p>
                              {product.DefaultVariation.SalePrice === 0 ? (
                                <p className="pricenormal">
                                  $
                                  {product.DefaultVariation.RegularPrice.toLocaleString()}{" "}
                                  <button>
                                    <img src={addcart} alt="Add to Cart"></img>
                                  </button>
                                </p>
                              ) : (
                                <p className="price">
                                  <span className="off">
                                    -
                                    {product.DefaultVariation.Percentage.toFixed(
                                      2
                                    ) + "%"}
                                  </span>
                                  <span className="was">
                                    <span className="fw-medium">Was:</span>{" "}
                                    <strike>
                                      $
                                      {product.DefaultVariation.RegularPrice.toLocaleString()}
                                    </strike>
                                  </span>
                                  $
                                  {product.DefaultVariation.SalePrice.toLocaleString()}{" "}
                                  <button>
                                    <img src={addcart} alt="Add to Cart"></img>
                                  </button>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {totalPage.length > 1 && (
                    <div className="row mt-4">
                      <div className="col-12">
                        <div className="pagination">
                          {totalPage.map((p) => {
                            return (
                              <div
                                className={
                                  p.index === page
                                    ? "pagination-pages active-page"
                                    : "pagination-pages"
                                }
                                onClick={() => pageChange(p.index)}
                              >
                                <a>{p.pageNumber}</a>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div id="filterAside" className="filterAsideBox">
                <div className="row mb-4">
                  <div className="col-12">
                    <button
                      type="button"
                      className="closeFilter"
                      onClick={closeFilterAside}
                    >
                      <FiChevronLeft /> Filters
                    </button>
                  </div>
                </div>
                {filters.length > 0 && (
                  <Accordion defaultActiveKey="0">
                    {filters.map((option, index) => {
                      return (
                        <Accordion.Item eventKey={index}>
                          {option.filterValues.length > 0 && (
                            <Accordion.Header>
                              {option.filterName}{" "}
                            </Accordion.Header>
                          )}
                          {option.filterName === "Category" ? (
                            <Accordion.Body>
                              <ul>
                                {option.filterValues.length > 0 &&
                                  option.filterValues.map((c) => {
                                    return (
                                      <li>
                                        <input
                                          type="checkbox"
                                          value={c.CategoryId}
                                          name="category"
                                          onClick={FilterProduct}
                                          className="d-none"
                                          id="categoryFilter"
                                        />{" "}
                                        <label for="categoryFilter">
                                          {c.CategoryName}
                                        </label>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </Accordion.Body>
                          ) : option.filterName === "Color" ? (
                            <Accordion.Body>
                              <ul>
                                {option.filterValues.length > 0 &&
                                  option.filterValues.map((color) => {
                                    return (
                                      <li key={color.Color}>
                                        <div>
                                          <input
                                            className="plCheckbox me-2"
                                            type="checkbox"
                                            value={color.AttributeValueId}
                                            name="color"
                                            onClick={FilterProduct}
                                            style={{
                                              backgroundColor:
                                                color.AttributeValue,
                                            }}
                                          />{" "}
                                          {color.AttributeName}
                                        </div>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </Accordion.Body>
                          ) : option.filterName === "Price" ? (
                            <Accordion.Body>
                              <ul>
                                {priceInterval.map((interval) => {
                                  return (
                                    <>
                                      <li>
                                        <input
                                          type="checkbox"
                                          name="price"
                                          value={interval}
                                          onClick={FilterProduct}
                                          className="d-none"
                                          id="priceFilter"
                                        />
                                        <label for="priceFilter">
                                          ${interval[0]} - ${interval[1]}
                                        </label>
                                      </li>
                                    </>
                                  );
                                })}
                              </ul>
                            </Accordion.Body>
                          ) : null}
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                )}
              </div>
              <div id="SortBox" className="sortListBox">
                <div className="row">
                  <div className="col-6">
                    <label className="sortTitle">Sort:</label>
                  </div>
                  <div className="col-6 text-end">
                    <button
                      onClick={closeSortList}
                      type="button"
                      className="closeSort"
                    >
                      <FiX />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <ul>
                      <li>Revelence</li>
                      <li>Price: Low to High</li>
                      <li>Price: High to Low</li>
                      <li>Availability</li>
                      <li>Rating</li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          )}
          {categoryProducts.length == 0 && (
            <>
              <div className="row">
                <div className="col-12">
                  <h3>No Products In This Category</h3>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
export default ListProducts;
