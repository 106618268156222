import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import product from '../../../images/products/product9.png';

const Checkout = (props) => {
    const [login, setLogin] = useState(false);
    const [value, setValue] = useState(0);

    useEffect(() => {
        props.setHeaderTitle("Checkout")
        props.setFooterActive(3);
    }, [])

    return (
        <>
            <div className='container Checkout'>
                <div className="row d-none d-md-block">
                    <div className="col-12 col-md-12">
                        <ul className="bread-crumb">
                            <li>Home</li>
                            <li>/</li>
                            <li><Link to="/Cart">Cart /</Link></li>
                            <li>Checkout</li>
                        </ul>
                    </div>
                </div>
                <div className="row d-none d-md-block">
                    <div className="col-12 col-md-12">
                        <h1>Checkout</h1>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 col-lg-9 Profile'>
                        <div className='infoBox mt-0'>
                            <div className='row'>
                                <div className='col-12'>
                                    <label className='title mb-0'>1 Personal Information</label>
                                </div>
                            </div>
                            <div className='row mt-25 mb-30'>
                                <div className='col-12 fontOverride'>
                                    {login ? (
                                        <>
                                            <span className='infoTitle pointer' onClick={() => setLogin(false)}>Create an Account  </span>
                                            <span className='title pointer'>|  Sign in</span>
                                        </>
                                    ) : (
                                        <>
                                            <span className='title pointer'>Create an Account  </span>
                                            <span className='infoTitle pointer' onClick={() => setLogin(true)}>|  Sign in</span>
                                        </>
                                    )}
                                </div>
                            </div>
                            {login ? (
                                <>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <label className='infoTitle'>Username</label>
                                        </div>
                                        <div className='col-9'>
                                            <input type="email" className='inputBox' placeholder='Type Here' />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <label className='infoTitle'>Password:</label>
                                        </div>
                                        <div className='col-9'>
                                            <div className='col-12'>
                                                <input type="Password" className='inputBox' placeholder='Type Here' />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <label className='infoTitle'>Full Name:</label>
                                        </div>
                                        <div className='col-9'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <input type="text" className='inputBox' placeholder='First Name' />
                                                </div>
                                                <div className='col-6'>
                                                    <input type="text" className='inputBox' placeholder='Last Name' />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-3'>
                                                    <input type="radio" id="mr" className='inputCheckbox' name='gender' />
                                                    <label for="mr" className='checkLabel ms-2'>Mr.</label>
                                                </div>
                                                <div className='col-3'>
                                                    <input type="radio" id="mrs" className='inputCheckbox' name='gender' />
                                                    <label for="mr" className='checkLabel ms-2'>Mrs.</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <label className='infoTitle'>Email</label>
                                        </div>
                                        <div className='col-9'>
                                            <input type="email" className='inputBox' placeholder='Type Here' />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <label className='infoTitle'>Phone Number</label>
                                        </div>
                                        <div className='col-9'>
                                            <div className='col-12'>
                                                <PhoneInput placeholder="Type here" className='inputBox' value={value} onChange={setValue} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <label className='infoTitle'>Password:</label>
                                        </div>
                                        <div className='col-9'>
                                            <div className='col-12'>
                                                <input type="Password" className='inputBox' placeholder='Type Here' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <input type="checkbox" id="g" className='inputCheckbox' name='guest' />
                                            <label for="g" className='checkLabel ms-2'>Continue as a guest</label>
                                        </div>
                                    </div>
                                </>
                            )}
                            <hr />
                            <div className='row'>
                                <div className='col-12'>
                                    <label className='title'>2 Address</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-3'>
                                    <label className='infoTitle'>Address Name:</label>
                                </div>
                                <div className='col-9'>
                                    <input type="text" className='inputBox' placeholder='Type Here' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-3'>
                                    <label className='infoTitle'>Country:</label>
                                </div>
                                <div className='col-9'>
                                    <select className='inputBoxSelect'>
                                        <option className='info' selected disabled>-- Choose a Country --</option>
                                        <option className='info'>Country</option>
                                    </select>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-3'>
                                    <label className='infoTitle'>City:</label>
                                </div>
                                <div className='col-9'>
                                    <select className='inputBoxSelect'>
                                        <option className='info' selected disabled>-- Choose a Country --</option>
                                        <option className='info'>Country</option>
                                    </select>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-3'>
                                    <label className='infoTitle'>Zip Code:</label>
                                </div>
                                <div className='col-9'>
                                    <input type="text" className='inputBox' placeholder='Type Here' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-3'>
                                    <label className='infoTitle'>Postal Code:</label>
                                </div>
                                <div className='col-9'>
                                    <input type="text" className='inputBox' placeholder='Type Here' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-3'>
                                    <label className='infoTitle'>Address:</label>
                                </div>
                                <div className='col-9'>
                                    <textarea placeholder='Type Address' className='textareaBox' cols="4" rows="3" />
                                </div>
                            </div>
                            <div className='row mt-20'>
                                <div className='col-12'>
                                    <label className='title mb-3'>Or Saved Address:</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <ul className='addresses p-0'>
                                        <li><input type="radio" id="1" className='inputCheckbox' name='address' /> Home</li>
                                        <li><input type="radio" id="2" className='inputCheckbox' name='address' /> Work</li>
                                        <li><input type="radio" id="3" className='inputCheckbox' name='address' /> Work 2</li>
                                    </ul>
                                </div>
                            </div>
                            <hr />
                            <div className='row mt-20'>
                                <div className='col-12'>
                                    <label className='title mb-3'>3 Shipping Method:</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <ul className='addresses p-0'>
                                        <li><input type="radio" id="1" className='inputCheckbox' name='shippping' /> Aramex</li>
                                        <li><input type="radio" id="2" className='inputCheckbox' name='shippping' /> Dhl</li>
                                        <li><input type="radio" id="3" className='inputCheckbox' name='shippping' /> Shop Pickup</li>
                                    </ul>
                                </div>
                            </div>
                            <hr />
                            <div className='row mt-20'>
                                <div className='col-12'>
                                    <label className='title mb-3'>4 Payment Method</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <ul className='addresses p-0'>
                                        <li><input type="radio" id="1" className='inputCheckbox' name='payment' /> Cash On Delivery</li>
                                        <li><input type="radio" id="2" className='inputCheckbox' name='payment' /> Paypal</li>
                                        <li><input type="radio" id="3" className='inputCheckbox' name='payment' /> Card</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-3'>
                                    <label className='infoTitle'>Name on Card:</label>
                                </div>
                                <div className='col-9'>
                                    <input type="text" className='inputBox' placeholder='Type Here' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-3'>
                                    <label className='infoTitle'>Card Pin:</label>
                                </div>
                                <div className='col-9'>
                                    <input type="text" className='inputBox' placeholder='Type Here' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-3 Profile'>
                        <div className='infoBox mt-0'>
                            <div className='row mb-30'>
                                <div className='col-12'>
                                    <label className='sideTitlep'>1 Item</label>
                                </div>
                            </div>
                            <div className='row mb-50'>
                                <div className='col-4'>
                                    <img src={product} className="CheckoutImg" />
                                </div>
                                <div className='col-8'>
                                    <p className='itemName'>Macbook Air 13inch 256GB M1 Gold <span>x1</span></p>
                                    <span className='itemPrice float-end'>$1,010.00</span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <label className='sideTitlep'>Subtotal</label>
                                </div>
                                <div className='col-6 text-end'>
                                    <label className='price'>$ 1,010.00</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <label className='sideTitlep'>Shipping</label>
                                </div>
                                <div className='col-6 text-end'>
                                    <label className='price'>$ 2.00</label>
                                </div>
                            </div>
                            <hr />
                            <div className='row'>
                                <div className='col-6'>
                                    <label className='sideTitlep red'>Total</label>
                                </div>
                                <div className='col-6 text-end'>
                                    <label className='price'>$ 1,012.00</label>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <button className='outBtn'>Done</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Checkout