import React, { useState } from 'react'
import { Global } from '../../../Variables/Global';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const ForgetPassword = () => {
    const [email, setEmail] = useState("")
    const navigate = useNavigate();


    function handleSubmit(e){
        const URL = Global.API_URL + "Customer/CustomerForgetPassword";
        fetch(URL, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.API_URL,
          },
          body: JSON.stringify({
            Email: email,
          }),
        }).then((response) => {
          if (response.ok) {
            setEmail("");
            toast.success("Kindly Check your email");
            navigate("/Reset-Password")
          } else {
            toast.error("This email does not exist, please register");
          }
        });
    }
    return (
        <>
            <div className="container mt-60">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <ul className="bread-crumb">
                            <li>Home</li>
                            <li>/</li>
                            <li>Profile</li>
                        </ul>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8">
                        <div className="authenticationCard">
                            <div className="row topBar">
                                <div className="col-12 text-center">
                                    <button className='active'>Forget Password</button>
                                </div>
                            </div>
                            <div className="row mb-35">
                                <div className="col-12 col-md-2"><label>Email:</label></div>
                                <div className="col-12 col-md-10">
                                    <input className="form-control" placeholder="Type Here" required onChange={(e) => setEmail(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="row mb-35 submit">
                                <div className="col-12 text-center">
                                    <button onClick={(e) => handleSubmit(e)}>Send Code</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgetPassword