import React from 'react'
import newsletter from "../../images/icons/newsletter.png";
import footerLogo from "../../images/footerLogo.png";
import { Link } from "react-router-dom";
import { AiFillFacebook, AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai";
import { BsEnvelope, BsFillTelephoneFill, BsFillGridFill, BsFillPersonFill } from "react-icons/bs";
import { FiHome } from "react-icons/fi";
import { ImCart } from "react-icons/im";
import { HiHeart } from "react-icons/hi";

const Footer = (props) => {
    return (
        <>
            <div className='d-none d-md-block desktopFooter'>
                <div className="newsLetterSection">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-lg-6">
                                <img src={newsletter} alt="newsletter" />
                                <span>Sign Up To Our Newsletter</span>
                            </div>
                            <div className="col-12 col-lg-6 form">
                                <input placeholder="Enter your email address" />
                                <button>Sign Up</button>
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-4 logos">
                                <img src={footerLogo} alt="Footer Logo" className="logo" />
                                <span className="aboutTitle">About Us</span>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Maecenas odio urna, ultrices quis sagittis eget, iaculis eu
                                    mi. Etiam ex arcu, tristique ut hendrerit nec, dapibus ut leo.
                                </p>
                                <h3>Contact Info</h3>
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/playmegastore">
                                            <AiFillFacebook />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/playmegastorelb">
                                            <AiOutlineInstagram />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <AiOutlineTwitter />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <BsEnvelope />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <BsFillTelephoneFill />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-6 info">
                                <h3>Contact Info</h3>
                                <div className="row">
                                    <div className="col-6">
                                        <ul>
                                            <li>
                                                <Link to="">Laptops & Computers</Link>
                                            </li>
                                            <li>
                                                <Link to="">Cameras & Photography</Link>
                                            </li>
                                            <li>
                                                <Link to="">Smart Phones & Tablets</Link>
                                            </li>
                                            <li>
                                                <Link to=""> Tv & Audio</Link>
                                            </li>
                                            <li>
                                                <Link to="">Gadgets</Link>
                                            </li>
                                            <li>
                                                <Link to=""> Car Electronics & Gps</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-6">
                                        <ul>
                                            <li>
                                                <Link to="">Printers & Ink</Link>
                                            </li>
                                            <li>
                                                <Link to="">Software</Link>
                                            </li>
                                            <li>
                                                <Link to="">Office Supplies</Link>
                                            </li>
                                            <li>
                                                <Link to=""> Computer Components</Link>
                                            </li>
                                            <li>
                                                <Link to="">Accessories</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 info">
                                <h3>Customer Care</h3>
                                <ul>
                                    <li>
                                        <Link to=""> My Account</Link>
                                    </li>
                                    <li>
                                        <Link to="">Order Tracking</Link>
                                    </li>
                                    <li>
                                        <Link to="">Customer Service</Link>
                                    </li>
                                    <li>
                                        <Link to="">Returns / Exchange</Link>
                                    </li>
                                    <li>
                                        <Link to="">F.A,Q.S</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="bottomText">Powered by Vision & More</div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
            <div className='d-md-none mobileFooter'>
                <footer>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='d-flex justify-content-between'>
                                    <div><Link to="/" className={props.footerActive == 1 ? "active" : ""}><FiHome/></Link></div>
                                    <div><Link to="/Category-List" className={props.footerActive == 2 ? "active" : ""}><BsFillGridFill/></Link></div>
                                    <div><Link to="/Cart" className={props.footerActive == 3 ? "active" : ""}><ImCart/></Link></div>
                                    <div><Link to="/Wishlist" className={props.footerActive == 4 ? "active" : ""}><HiHeart/></Link></div>
                                    <div><Link to="/Profile" className={props.footerActive == 5 ? "active" : ""}><BsFillPersonFill/></Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
}

export default Footer