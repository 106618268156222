import addcart from "../../../images/icons/addcart.png";
import product from "../../../images/products/product.png";
import product1 from "../../../images/products/product8.png";
import product2 from "../../../images/products/product9.png";
import activeWishListIcon from "../../../images/icons/inWishList.png";
import { useEffect } from "react";
import { FaList } from "react-icons/fa";
import { BsGrid3X3GapFill, BsGridFill } from "react-icons/bs";
import { FiX } from "react-icons/fi";

const WishList = (props) => {
  function openSortList() {
    const box = document.getElementById("SortBox");
    box.classList.add("sortListBoxShow");
  }

  function closeSortList() {
    const box = document.getElementById("SortBox");
    box.classList.remove("sortListBoxShow");
  }
  useEffect(() => {
    props.setHeaderTitle("WishList")
    props.setFooterActive(4);
  }, [])

  return (
    <>
      <div className="container wishlistSection ">
        <div className="row  d-none d-md-block">
          <div className="col-12 col-md-12">
            <ul className="bread-crumb">
              <li>Home</li>
              <li>/</li>
              <li>Wishlist</li>
            </ul>
          </div>
        </div>
        <div className="row  d-none d-md-block">
          <div className="col-12 col-md-12">
            <h1>Wishlist</h1>
          </div>
        </div>
        <div className="row topFilterLine d-none d-md-flex">
          {/* <div className="col-3">
            <label className="filterTitle">Filters</label>
          </div>
          <div className="col-5">
            <span className="noProducts">3 Products</span>
            <span className="sortBy">
              <strong>Sort: </strong>
              <select>
                <option>Relevence</option>
              </select>
            </span>
          </div>
          <div className="col-4 listBy text-end">
            <FaList />
            <BsGridFill />
            <BsGrid3X3GapFill />
          </div> */}
        </div>
        <div className="row mobileFilters d-md-none mb-3">
          <div className="col-8">
            <span className="Filtername" onClick={openSortList}><span className="red">Sort: </span>by Relevance</span>
          </div>
          <div className="col-4 listByMobile text-end">
            <FaList />
            <span className="red"><BsGridFill /></span>
            <BsGrid3X3GapFill />
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-md-3">
            <div className="item">
              <button className="btnWishList">
                <img src={activeWishListIcon} alt="In WishList" ></img>
              </button>
              <img src={product} alt="Product" />
              <div className="content">
                <h5>Choetech Portable Power Station 300W</h5>
                <p className="sub">Playmegastore</p>
                <p className="price">
                  $335.00{" "}
                  <button>
                    <img src={addcart} alt="Add to Cart"></img>
                  </button>
                </p>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="item">
              <button className="btnWishList">
                <img src={activeWishListIcon} alt="In WishList" ></img>
              </button>
              <img src={product1} alt="Product1" />
              <div className="content">
                <h5>Choetech Portable Power Station 300W</h5>
                <p className="sub">Playmegastore</p>
                <p className="price">
                  $335.00{" "}
                  <button>
                    <img src={addcart} alt="Add to Cart"></img>
                  </button>
                </p>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="item">
              <button className="btnWishList">
                <img src={activeWishListIcon} alt="In WishList" ></img>
              </button>
              <img src={product2} alt="Product2" />
              <div className="content">
                <h5>Choetech Portable Power Station 300W</h5>
                <p className="sub">Playmegastore</p>
                <p className="price">
                  $335.00{" "}
                  <button>
                    <img src={addcart} alt="Add to Cart"></img>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="SortBox" className="sortListBox">
          <div className="row">
            <div className="col-6">
              <label className="sortTitle">Sort:</label>
            </div>
            <div className="col-6 text-end">
              <button onClick={closeSortList} type="button" className="closeSort"><FiX /></button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ul>
                <li>Revelence</li>
                <li>Price: Low to High</li>
                <li>Price: High to Low</li>
                <li>Availability</li>
                <li>Rating</li>
              </ul>
            </div>
          </div>
        </div>
      </div></>
  )
}
export default WishList