import React, { useEffect, useState } from 'react'
import dp from '../../images/profile/dp.png';
import { Dropdown } from 'react-bootstrap';
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { Rating } from "react-simple-star-rating";
import Util from '../../Classes/Util'
import { Global } from '../../Variables/Global';
import { useNavigate } from 'react-router-dom';
import { MdOutlineKeyboardBackspace, MdSettings } from 'react-icons/md';
import logo from '../../images/footerLogo.png'
import { BiChevronRight } from 'react-icons/bi';
import { RiFileList3Line, RiPencilFill } from 'react-icons/ri';
import { BsFillPersonFill, BsGlobe, BsHeadset, BsLink45Deg, BsQuestionCircle } from 'react-icons/bs';

const Profile = (props) => {
  const [page, setPage] = useState(0);
  const [value, setValue] = useState(0);
  const [info, setInfo] = useState({
    Email: "",
    FirstName: "",
    LastName: ""
  });
  const navigate = useNavigate();

  const getCustomerInfo = async () => {
    const id = Util.decrypt(localStorage.getItem("Number"))
    const URL = Global.API_URL + "Profile/GetCustomerProfile/" + id;
    await fetch(URL, {
      method: "Get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
      })
      .then((result) => {
        setInfo(result)
      });
  }

const logout = () => {
  sessionStorage.clear();
  localStorage.clear();
  window.location.href = "/"
}

  useEffect(() => {
    props.setHeaderTitle("Profile")
    props.setFooterActive(5);
    getCustomerInfo();
  }, [])

  return (
    <>
      <div className="container Profile">
        <div className='row d-md-none'>
          <div className='col-12 text-center position-relative'>
            {/* <img src={dp} className="mobileDp" />
            <br /> */}
            <br />
            <label className='nameMobile'>{info.FirstName + " " + info.LastName}</label>
            <MdSettings className={page == 8 || page == 4 ? "settingsIcon activeSettings" : "settingsIcon"} onClick={() => setPage(8)} />
          </div>
        </div>
        {page !== 8 && (
          <div className='row d-md-none mt-3 mobileTabsBorder'>
            <div className='col-3'>
              <label onClick={() => setPage(0)} className={page == 0 || page == 5 ? "tabMobileActive" : "tabMobile"}>Information</label>
            </div>
            <div className='col-3'>
              <label onClick={() => setPage(1)} className={page == 1 || page == 6 || page == 7 ? "tabMobileActive" : "tabMobile"}>Orders</label>
            </div>
            <div className='col-3'>
              <label onClick={() => setPage(2)} className={page == 2 ? "tabMobileActive" : "tabMobile"}>Reviews</label>
            </div>
            <div className='col-3'>
              <label onClick={() => setPage(3)} className={page == 3 ? "tabMobileActive" : "tabMobile"}>Address Book</label>
            </div>
          </div>
        )}
        <div className='row d-none d-md-flex'>
          <div className='col-md-7 col-lg-6 col-xl-4'>
            <div className="row">
              <div className="col-12 col-md-12">
                <ul className="bread-crumb">
                  <li>Home</li>
                  <li>/</li>
                  <li>Profile</li>
                </ul>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 '>
                <label className='topTitle'>{info.FirstName + " " + info.LastName}</label>
              </div>
            </div>
          </div>
          <div className='col-2 text-center'>
            <img src={dp} className="dp" />
          </div>
          <div className='col-md-3 col-lg-4 col-xl-6 m-auto'>
            <hr />
          </div>
        </div>
        <div className='row d-none d-md-block d-lg-none text-end'>
          <div className='col-12 ProfileMenu'>
            <Dropdown>
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                Profile Menu
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setPage(0)} className={page == 0 ? "red" : ""}>My Information</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => setPage(1)} className={page == 1 || page == 6 || page == 7 ? "red" : ""}>My Orders</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => setPage(2)} className={page == 2 ? "red" : ""}>My Reviews</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => setPage(3)} className={page == 3 ? "red" : ""}>My Address Book</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => setPage(4)} className={page == 4 ? "red" : ""}>Settings</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => setPage(5)} className={page == 5 ? "red" : ""}>Edit Profile</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="/">Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 col-lg-9'>
            {page == 0 && (
              <div className='infoBox'>
                <div className='row'>
                  <div className='col-12'>
                    <label className='title'>My Information</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Full Name:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <label className='info'>{info.FirstName + " " + info.LastName}</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>E-mail:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <label className='info'>{info.Email}</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Phone Number:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <label className='info'>+961 76 068 155</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 col-lg-6'>
                    <div className='row'>
                      <div className='col-4 col-md-3 col-lg-6'>
                        <label className='infoTitle'>D.O.B:</label>
                      </div>
                      <div className='col-8 col-md-9 col-lg-6'>
                        <label className='info'>19 -1-200</label>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-lg-6'>
                    <div className='row'>
                      <div className='col-4 col-md-3'>
                        <label className='infoTitle'>Language:</label>
                      </div>
                      <div className='col-8 col-md-9'>
                        <label className='info'>English</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Billing Address:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <label className='info'>Hamra Bliss Street, In Cha Time Building in front of AUB, Teras Bldg. 3rd floor  Beirut, Lebanon</label>
                  </div>
                </div>
                <div className='d-md-none'>
                  <div className='row'>
                    <div className='col-12'>
                      <button className={page == 5 ? 'editBtn activeEdit' : 'editBtn'} onClick={() => setPage(5)}>Edit</button>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <button className='outBtn' onClick={() => logout()}>Log- out</button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {page == 1 && (
              <>
                <div className='infoBox d-none d-md-block'>
                  <div className='row'>
                    <div className='col-12'>
                      <label className='title'>My Orders</label>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <p className='orderInfos'>
                        Your Order #: <span className='orderInfo'>370 -WUTTUM-3480</span>
                        Your Date: <span className='orderInfo'>3-8-2022</span>
                        Your Data: <span className='orderInfo'>Being Shipped</span>
                        <span className='float-end'>
                          <button className='orderBtns' onClick={() => setPage(6)}>Add Review</button>
                          <button className='orderBtns' onClick={() => setPage(7)}>View Details</button>
                          <button className='orderBtns'>Re-Order</button>
                        </span>
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className='row'>
                    <div className='col-12'>
                      <p className='orderInfos'>
                        Your Order #: <span className='orderInfo'>370 -WUTTUM-3480</span>
                        Your Date: <span className='orderInfo'>3-8-2022</span>
                        Your Data: <span className='orderInfo'>Being Shipped</span>
                        <span className='float-end'>
                          <button className='orderBtns' onClick={() => setPage(6)}>Add Review</button>
                          <button className='orderBtns' onClick={() => setPage(7)}>View Details</button>
                          <button className='orderBtns'>Re-Order</button>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='infoBox d-md-none'>
                  <div className='row'>
                    <div className='col-4'>
                      <label className='infoTitle'>Your Order #:</label>
                    </div>
                    <div className='col-8'>
                      <label className='mobileinfoValue'>370 -WUTTUM-3480</label>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-4'>
                      <label className='infoTitle'>Order Details:</label>
                    </div>
                    <div className='col-8'>
                      <p className='mobileinfoValue'>
                        1  Macbook Air 13inch 256GB M1 Gold
                        <br />
                        Intel Core I5 1.1GHZ, 8GB ram LPDDR4, 512GB SSD Drive, Intel Iris Plus Graphics, 13.3" Retina 2560x1600, Touch ID
                        <br />
                        $1,010.00
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-4'>
                      <label className='infoTitle'>Payment Method:</label>
                    </div>
                    <div className='col-8'>
                      <label className='mobileinfoValue'>Cash on Delivery</label>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-4'>
                      <label className='infoTitle'>Shipment:</label>
                    </div>
                    <div className='col-8'>
                      <label className='mobileinfoValue'>Aramex</label>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-4'>
                      <label className='infoTitle'>Your Order #:</label>
                    </div>
                    <div className='col-8'>
                      <label className='mobileinfoValue'>370 -WUTTUM-3480</label>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <button className="editBtn" onClick={() => navigate("/Order-Tracking")}>Track Order</button>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <button className='outBtn' onClick={() => logout()}>Log- out</button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {page == 2 && (
              <div className='infoBox'>
                <div className='row'>
                  <div className='col-12'>
                    <label className='title'>My Reviews</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Product Name:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <label className='info'>
                      1  Macbook Air 13inch 256GB M1 Gold
                    </label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Review:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <p className='subInfo'>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam rutrum convallis leo, facilisis faucibus orci faucibus vitae. Ut neque ipsum, ullamcorper vitae ipsum sed, imperdiet eleifend augue.
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Rates:</label>
                  </div>
                  <div className='col-8 col-md-9 m-auto'>
                    <ul className='rates p-0'>
                      <li><AiFillStar /></li>
                      <li><AiFillStar /></li>
                      <li><AiFillStar /></li>
                      <li><AiOutlineStar /></li>
                      <li><AiOutlineStar /></li>
                    </ul>
                  </div>
                </div>
                <div className='d-md-none'>
                  <div className='row'>
                    <div className='col-12'>
                      <button className='editBtn' onClick={() => setPage(6)}>Add</button>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <button className='outBtn' onClick={() => logout()}>Log- out</button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {page == 3 && (
              <div className='infoBox'>
                <div className='row'>
                  <div className='col-12'>
                    <label className='title mb-3'>My Address Book</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <ul className='addresses'>
                      <li>Home</li>
                      <li>Work</li>
                      <li>Work 2</li>
                    </ul>
                  </div>
                </div>
                <hr />
                <div className='row'>
                  <div className='col-12'>
                    <label className='title'>Add Address</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Address Name:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <input type="text" className='inputBox' placeholder='Type Here' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Country:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <select className='inputBoxSelect'>
                      <option className='info' selected disabled>-- Choose a Country --</option>
                      <option className='info'>Country</option>
                    </select>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>City:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <select className='inputBoxSelect'>
                      <option className='info' selected disabled>-- Choose a Country --</option>
                      <option className='info'>Country</option>
                    </select>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Zip Code:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <input type="text" className='inputBox' placeholder='Type Here' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Postal Code:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <input type="text" className='inputBox' placeholder='Type Here' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Address:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <textarea placeholder='Type Address' className='textareaBox' cols="4" rows="3" />
                  </div>
                </div>

                <div className='d-md-none'>
                  <div className='row'>
                    <div className='col-12'>
                      <button className='editBtn' onClick={() => setPage(1)}>Submit</button>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <button className='outBtn' onClick={() => logout()}>Log- out</button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {page == 4 && (
              <div className='infoBox'>
                <div className='row d-none d-md-block'>
                  <div className='col-12'>
                    <label className='title'>Settings</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Change Email:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <input type="text" className='inputBox' placeholder='Type Here Old' />
                    <br />
                    <input type="text" className='inputBox' placeholder='Type Here New' />
                    <br />
                    <input type="text" className='inputBox' placeholder='Confirm Email' />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-12 text-end'>
                    <button className='submitBtn'>Change Email</button>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Change Password:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <input type="text" className='inputBox' placeholder='Type Here Old' />
                    <br />
                    <input type="text" className='inputBox' placeholder='Type Here New' />
                    <br />
                    <input type="text" className='inputBox' placeholder='Confirm New' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 text-end'>
                    <button className='submitBtn'>Change Password</button>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Prefereces:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <div className='row'>
                      <div className='col-6'>
                        <select className='inputBoxSelect'>
                          <option className='info' selected disabled>Language</option>
                          <option className='info'>English</option>
                        </select>
                      </div>
                      <div className='col-6'>
                        <select className='inputBoxSelect'>
                          <option className='info' selected disabled>Currency</option>
                          <option className='info'>USD</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {page == 5 && (
              <div className='infoBox'>
                <div className='row'>
                  <div className='col-12'>
                    <label className='title'>My Information</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Full Name:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <div className='row'>
                      <div className='col-6'>
                        <input type="text" className='inputBox' placeholder='First Name' />
                      </div>
                      <div className='col-6'>
                        <input type="text" className='inputBox' placeholder='Last Name' />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-6 col-md-3'>
                        <input type="radio" id="mr" className='inputCheckbox' name='gender' />
                        <label for="mr" className='checkLabel ms-2'>Mr.</label>
                      </div>
                      <div className='col-6 col-md-3'>
                        <input type="radio" id="mrs" className='inputCheckbox' name='gender' />
                        <label for="mr" className='checkLabel ms-2'>Mrs.</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>E-mail:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <input type="email" className='inputBox' placeholder='example@mail.com' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Phone Number:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <PhoneInput value={value} onChange={setValue} placeholder="Type here" className='inputBox' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 col-lg-6'>
                    <div className='row'>
                      <div className='col-4 col-md-3 col-lg-6'>
                        <label className='infoTitle'>D.O.B:</label>
                      </div>
                      <div className='col-8 col-md-9 col-lg-6'>
                        <input type="text"
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => (e.target.type = "text")} placeholder="MM/DD/YYYY" className='inputBox' />
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-lg-6'>
                    <div className='row'>
                      <div className='col-4 col-md-3'>
                        <label className='infoTitle'>Language:</label>
                      </div>
                      <div className='col-8 col-md-9'>
                        <select className='inputBoxSelect'>
                          <option className='info' selected disabled>-- Choose a Language --</option>
                          <option className='info'>English</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Billing Address:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <div className='row'>
                      <div className='col-6'>
                        <select className='inputBoxSelect'>
                          <option className='info' selected disabled>-- Choose a Country --</option>
                          <option className='info'>Country</option>
                        </select>
                      </div>
                      <div className='col-6'>
                        <select className='inputBoxSelect'>
                          <option className='info' selected disabled>-- Choose a City --</option>
                          <option className='info'>City</option>
                        </select>
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-12'>
                        <textarea placeholder='Type Address' className='textareaBox' cols="4" rows="3" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row d-md-none'>
                  <div className='col-12'>
                    <button className='editBtn activeEdit' onClick={() => setPage(1)}>Done</button>
                  </div>
                </div>
              </div>
            )}
            {page == 6 && (
              <div className='infoBox'>
                <div className='row'>
                  <div className='col-12'>
                    <label className='title'>My Orders</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Your Order #:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <label className='info'>370 -WUTTUM-3480</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Product Name:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <label className='info'>
                      1  Macbook Air 13inch 256GB M1 Gold
                    </label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Review:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <textarea placeholder='Type Review Here Please' className='textareaBox p-2' cols="4" rows="3" />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 col-md-3'>
                    <label className='infoTitle'>Rate:</label>
                  </div>
                  <div className='col-8 col-md-9'>
                    <Rating className="rateInput" />
                  </div>
                </div>

                <div className='d-md-none'>
                  <div className='row'>
                    <div className='col-12'>
                      <button className='editBtn' onClick={() => setPage(1)}>Submit</button>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <button className='outBtn' onClick={() => logout()}>Log- out</button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {page == 7 && (
              <div className='infoBox'>
                <div className='row'>
                  <div className='col-12'>
                    <label className='title'>My Orders</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-3'>
                    <label className='infoTitle'>Your Order #:</label>
                  </div>
                  <div className='col-9'>
                    <label className='info'>370 -WUTTUM-3480</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-3'>
                    <label className='infoTitle'>Order Details:</label>
                  </div>
                  <div className='col-6'>
                    <p className='info'>
                      1  Macbook Air 13inch 256GB M1 Gold
                      <br />
                      <span className='subInfo'>Intel Core I5 1.1GHZ, 8GB ram LPDDR4, 512GB SSD Drive, Intel Iris Plus Graphics, 13.3" Retina 2560x1600, Touch ID</span>
                    </p>
                  </div>
                  <div className='col-3'>
                    <label className='title'>$1,010.00</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-3'>
                    <label className='infoTitle'>Payment Method:</label>
                  </div>
                  <div className='col-9'>
                    <label className='info'>Cash on Delivery</label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-3'>
                    <label className='infoTitle'>Shipment:</label>
                  </div>
                  <div className='col-9'>
                    <label className='info'>Aramex</label>
                  </div>
                </div>
              </div>
            )}
            {page == 8 && (
              <div className='infoBox'>
                <div className='row mobileTabsBorder'>
                  <div className='col-12 text-center'>
                    <label className='title mb-0'>Settings</label>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-12'>
                    <img src={logo} className="settingsIcons"/>
                    <label className='settingsTitle'>About Us</label>
                    <span className='rightArrowSettings'><BiChevronRight/></span>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-12'>
                    <BsFillPersonFill className="settingsIcons" onClick={() => setPage(4)}/>
                    <label className='settingsTitle'>Manage Account</label>
                    <span className='rightArrowSettings'><BiChevronRight/></span>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-12'>
                    <BsLink45Deg className="settingsIcons"/>
                    <label className='settingsTitle'>Link Your Account</label>
                    <span className='rightArrowSettings'><BiChevronRight/></span>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-12'>
                    <BsGlobe className="settingsIcons"/>
                    <label className='settingsTitle'>App Language</label>
                    <span className='rightArrowSettings'><BiChevronRight/></span>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-12'>
                    <RiPencilFill className="settingsIcons"/>
                    <label className='settingsTitle'>Report A Problem</label>
                    <span className='rightArrowSettings'><BiChevronRight/></span>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-12'>
                    <BsQuestionCircle className="settingsIcons"/>
                    <label className='settingsTitle'>F.A.Q.S</label>
                    <span className='rightArrowSettings'><BiChevronRight/></span>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-12'>
                    <BsHeadset className="settingsIcons"/>
                    <label className='settingsTitle'>Customer Service</label>
                    <span className='rightArrowSettings'><BiChevronRight/></span>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-12'>
                    <MdOutlineKeyboardBackspace className="settingsIcons"/>
                    <label className='settingsTitle'>Returns / Exchange</label>
                    <span className='rightArrowSettings'><BiChevronRight/></span>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-12'>
                    <RiFileList3Line className="settingsIcons"/>
                    <label className='settingsTitle'>Terms Of Service</label>
                    <span className='rightArrowSettings'><BiChevronRight/></span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='d-none d-md-none d-lg-block col-lg-3'>
            <div className='infoBox'>
              <div className='row'>
                <div className='col-12 text-center' onClick={() => setPage(0)}>
                  <label className={page == 0 || page == 5 ? "sideTitleActive" : 'sideTitle'}>My Information</label>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 text-center' onClick={() => setPage(1)}>
                  <label className={page == 1 || page == 6 || page == 7 ? "sideTitleActive" : 'sideTitle'}>My Orders</label>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 text-center' onClick={() => setPage(2)}>
                  <label className={page == 2 ? "sideTitleActive" : 'sideTitle'}>My Reviews</label>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 text-center' onClick={() => setPage(3)}>
                  <label className={page == 3 ? "sideTitleActive" : 'sideTitle'}>My Address Book</label>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 text-center' onClick={() => setPage(4)}>
                  <label className={page == 4 ? "sideTitleActive sideNoBorder" : 'sideTitle sideNoBorder'}>Settings</label>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <button className={page == 5 ? 'editBtn activeEdit' : 'editBtn'} onClick={() => setPage(5)}>Edit</button>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <button className='outBtn' onClick={() => logout()}>Log- out</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile