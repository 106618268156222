import delivery from "../../images/icons/delivery.png";
import exchange from "../../images/icons/exchange.png";
import card from "../../images/icons/card.png";
import rightRedArrow from "../../images/icons/rightRedArrow.png";

import addcart from "../../images/icons/addcart.png";
import newsletter from "../../images/icons/newsletter.png";

import wishListIcon from "../../images/icons/wishlist.png";
import activeWishListIcon from "../../images/icons/inWishList.png";

import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useEffect, useState } from "react";
import { Global } from "../../Variables/Global";
import axios from "axios";
import Util from "../../Classes/Util";

const Home = (props) => {
  const [slider1, setSlider1] = useState([]);
  const [slider2, setSlider2] = useState([]);
  const [widget1, setWidget1] = useState([]);
  const [widget2, setWidget2] = useState([]);
  const [widget3, setWidget3] = useState([]);
  const [widget4, setWidget4] = useState([]);
  const [widget5, setWidget5] = useState([]);
  const [widget6, setWidget6] = useState([]);
  const [widget7, setWidget7] = useState([]);
  const [widget8, setWidget8] = useState([]);
  const [wName6, setWName6] = useState("");
  const [featured, setFeatured] = useState([]);
  const [onsale, setOnsale] = useState([]);
  const [toprated, setToprated] = useState([]);
  const [bestSeller, setBestSeller] = useState([]);
  const [lowStock, setLowStock] = useState([]);
  const [categoryWidget, setCatgeryWidget] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  SwiperCore.use([Autoplay, Pagination, Navigation]);

  const getSliders = async (sliderId) => {
    setIsLoading(true);
    const URL = Global.API_URL + "Slider/GetSlidesBySliderId/" + sliderId;
    await fetch(URL, {
      method: "Get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
      })
      .then((result) => {
        switch (sliderId) {
          case 1:
            setSlider1(result);
            break;
          case 2:
            setSlider2(result);
            break;
        }
      });
  };

  const getWidgets = async (widgetId) => {
    setIsLoading(true);
    const URL = Global.API_URL + "Widgets/GetAssignedCategories/" + widgetId;
    await fetch(URL, {
      method: "Get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
      })
      .then((result) => {
        switch (widgetId) {
          case 1:
            setWidget1(result);
            break;
          case 2:
            setWidget2(result);
            break;
          case 3:
            setWidget3(result);
            getProductByCategoryId(result[0].CategoryId, 3);
            break;
          case 4:
            setWidget4(result);
            getProductByCategoryId(result[0].CategoryId, 4);
            break;
          case 5:
            setWidget5(result);
            getProductByCategoryId(result[0].CategoryId, 5);
            break;
          case 6:
            setWidget6(result);
            setWName6(result[0].Title);
            break;
          case 7:
            setWidget7(result);
            getProductByCategoryId(result[0].CategoryId, 7);
            break;
          case 8:
            setWidget8(result);
            getProductByCategoryId(result[0].CategoryId, 8);
            break;
        }
      });

    setIsLoading(false);
  };

  const getProductByCategoryId = async (categoryId, widgetId) => {
    var groupId;
    if (localStorage.getItem("WishList")) {
      groupId = 4;
    } else {
      groupId = 3;
    }
    const res = await axios.get("https://geolocation-db.com/json/");
    var country;
    fetch(
      Global.API_URL +
        "Country/GetCoutryByCountryCode/" +
        res.data.country_code,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        } else {
          return "";
        }
      })
      .then((Result) => {
        country = Result.CountryId;
        const offset = new Date().getTimezoneOffset();
        fetch(Global.API_URL + "Products/GetProductsByCategoryId", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.API_URL,
          },
          body: JSON.stringify({
            categoryId: categoryId,
            pageId: 0,
            count: 7,
            offset: offset,
            attributes: [],
            categories: [],
            prices: [],
            groupId: groupId,
            countryId: country,
          }),
        })
          .then((Response) => {
            if (Response.ok) return Response.json();
            else throw Error("Did Not Receive expected data");
          })
          .then((Result) => {
            switch (widgetId) {
              case 3:
                setFeatured(Result);
                break;
              case 4:
                setOnsale(Result);
                break;
              case 5:
                setToprated(Result);
                break;
              case 7:
                setBestSeller(Result);
                break;
              case 8:
                setLowStock(Result);
                break;
            }
          });
      });
  };

  const handleAddCart = (product) => {
    var cartId = sessionStorage.getItem("GuestCart");
    cartId = Util.decrypt(cartId);
    var cartProduct = {
      cartId: cartId,
      productId: product.ProductId,
      variationId: product.DefaultVariation.ProductVariationId,
      quantity: 1,
    };
    var axios = require("axios");

    var config = {
      method: "post",
      url: Global.API_URL + "Cart/AddItemToCart",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: cartProduct,
    };

    axios(config)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    props.setHeaderTitle("Home");
    props.setFooterActive(1);
    window.scrollTo(0, 0);
    getSliders(1);
    getSliders(2);
    getWidgets(1);
    getWidgets(2);
    getWidgets(3);
    getWidgets(4);
    getWidgets(5);
    getWidgets(6);
    getWidgets(7);
    getWidgets(8);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <>
          {slider1.map((first) => {
            return (
              <Swiper
                className="mainSlider"
                spaceBetween={50}
                slidesPerView={1}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
              >
                {first.slides.map((slide) => {
                  return (
                    <SwiperSlide>
                      <Link to={slide.Url}>
                        <img src={Global.PHOTO_URL + slide.SlideImage} />
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            );
          })}

          <div className="container infoBar d-none d-md-block">
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="item">
                  <div className="icon">
                    <img src={delivery} alt="Delivery"></img>
                  </div>
                  <div className="text">
                    <h5>Delivery</h5>
                    <p>All Around Lebanon</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 middleBar">
                <div className="item">
                  <div className="icon">
                    <img src={exchange} alt="Delivery"></img>
                  </div>
                  <div className="text">
                    <h5>Returns / Exchange</h5>
                    <p>3 days to return or exchange product</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="item">
                  <div className="icon">
                    <img src={card} alt="Card"></img>
                  </div>
                  <div className="text">
                    <h5>Payment</h5>
                    <p>We accept cash on delivery</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container catergorySection">
            <div className="row">
              <div className="col-12 col-md-6">
                {widget1.map((w) => {
                  return (
                    <Link
                      to={
                        "/Category/" +
                        w.CategoryName.replace(/ /g, "-") +
                        "/" +
                        w.CategoryId
                      }
                    >
                      <img
                        src={Global.PHOTO_URL + w.CategoryImage}
                        alt="Category 1"
                      />
                      {/* {console.log(Global.PHOTO_URL + w.CategoryImage)} */}
                      <div className="box">
                        <div className="content">
                          <h3>{w.CategoryName}</h3>
                          <Link
                            to={
                              "/Category/" +
                              w.CategoryName.replace(/ /g, "-") +
                              "/" +
                              w.CategoryId
                            }
                            className="linkto"
                          >
                            Start Buying
                          </Link>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
              <div className="col-12 col-md-6">
                {widget2.map((w) => {
                  return (
                    <Link
                      to={
                        "/Category/" +
                        w.CategoryName.replace(/ /g, "-") +
                        "/" +
                        w.CategoryId
                      }
                    >
                      <img
                        src={Global.PHOTO_URL + w.CategoryImage}
                        alt="Category 2"
                      />
                      <div className="box">
                        <div className="content">
                          <h3>{w.CategoryName}</h3>
                          <Link
                            to={
                              "/Category/" +
                              w.CategoryName.replace(/ /g, "-") +
                              "/" +
                              w.CategoryId
                            }
                            className="linkto"
                          >
                            Start Buying
                          </Link>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="container tabSection">
            <div className="row">
              <div
                className={
                  categoryWidget === 1
                    ? "col-4 col-md-4 active"
                    : "col-4 col-md-4"
                }
              >
                <h4 onClick={() => setCatgeryWidget(1)}>
                  {widget3.map((w) => w.Title)}
                </h4>
              </div>
              <div
                className={
                  categoryWidget === 2
                    ? "col-4 col-md-4 active"
                    : "col-4 col-md-4"
                }
              >
                <h4 onClick={() => setCatgeryWidget(2)}>
                  {widget4.map((w) => w.Title)}
                </h4>
              </div>
              <div
                className={
                  categoryWidget === 3
                    ? "col-4 col-md-4 active"
                    : "col-4 col-md-4"
                }
              >
                <h4 onClick={() => setCatgeryWidget(3)}>
                  {widget5.map((w) => w.Title)}
                </h4>
              </div>
            </div>
            {categoryWidget == 1 ? (
              <>
                <div className="row">
                  <div className="col-12 col-md-12">
                    {widget3.map((w) => {
                      return (
                        <Swiper
                          className="productsSlider"
                          spaceBetween={50}
                          breakpoints={{
                            0: {
                              slidesPerView: 1,
                            },
                            500: {
                              slidesPerView: 1,
                            },
                            600: {
                              slidesPerView: 2,
                            },
                            800: {
                              slidesPerView: 2,
                            },
                            1100: {
                              slidesPerView: 3,
                            },
                            1200: {
                              slidesPerView: 3,
                            },
                            1300: {
                              slidesPerView: 4,
                            },
                          }}
                        >
                          {featured.map((product) => {
                            return (
                              <SwiperSlide>
                                <div className="item" key={product.ProductId}>
                                  <div className="imgBox">
                                    <Link
                                      to={
                                        "/DetailedProduct/" +
                                        product.ProductName.replace(/ /g, "-") +
                                        "/" +
                                        product.ProductId
                                      }
                                    >
                                      <img
                                        src={
                                          Global.PHOTO_URL +
                                          product.DefaultVariation.Image1
                                        }
                                        alt={product.ProductName}
                                      />
                                    </Link>
                                  </div>
                                  <button className="btnWishList">
                                    <img
                                      src={wishListIcon}
                                      alt="WishList"
                                    ></img>
                                    <img
                                      src={activeWishListIcon}
                                      alt="In WishList"
                                      style={{ display: "none" }}
                                    ></img>
                                  </button>
                                  <div className="content">
                                    <Link
                                      to={
                                        "/DetailedProduct/" + product.ProductId
                                      }
                                    >
                                      <h5>{product.ProductName}</h5>
                                    </Link>
                                    <p className="sub">Playmegastore</p>
                                    {product.DefaultVariation.SalePrice ===
                                    0 ? (
                                      <p className="pricenormal">
                                        ${product.DefaultVariation.RegularPrice.toLocaleString()}{" "}
                                        <button
                                          onClick={() => handleAddCart(product)}
                                        >
                                          <img
                                            src={addcart}
                                            alt="Add to Cart"
                                          ></img>
                                        </button>
                                      </p>
                                    ) : (
                                      <p className="price">
                                        <span className="off">
                                          -
                                          {product.DefaultVariation.Percentage.toFixed(
                                            2
                                          ) + "%"}
                                        </span>
                                        <span className="was">
                                          <span className="fw-medium">
                                            Was:
                                          </span>{" "}
                                          <strike>
                                            $
                                            {
                                              product.DefaultVariation
                                                .RegularPrice.toLocaleString()
                                            }
                                          </strike>
                                        </span>
                                        ${product.DefaultVariation.SalePrice.toLocaleString()}{" "}
                                        <button
                                          onClick={() => handleAddCart(product)}
                                        >
                                          <img
                                            src={addcart}
                                            alt="Add to Cart"
                                          ></img>
                                        </button>
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : null}
            {categoryWidget == 2 ? (
              <>
                <div className="row">
                  <div className="col-12 col-md-12">
                    {widget4.map((w) => {
                      return (
                        <Swiper
                          className="productsSlider"
                          spaceBetween={50}
                          breakpoints={{
                            0: {
                              slidesPerView: 1,
                            },
                            500: {
                              slidesPerView: 1,
                            },
                            600: {
                              slidesPerView: 2,
                            },
                            800: {
                              slidesPerView: 2,
                            },
                            1100: {
                              slidesPerView: 3,
                            },
                            1200: {
                              slidesPerView: 3,
                            },
                            1300: {
                              slidesPerView: 4,
                            },
                          }}
                        >
                          {onsale.map((product) => {
                            return (
                              <SwiperSlide>
                                <div className="item" key={product.ProductId}>
                                  <div className="imgBox">
                                    <Link
                                      to={
                                        "/DetailedProduct/" +
                                        product.ProductName.replace(/ /g, "-") +
                                        "/" +
                                        product.ProductId
                                      }
                                    >
                                      <img
                                        src={
                                          Global.PHOTO_URL +
                                          product.DefaultVariation.Image1
                                        }
                                        alt={product.ProductName}
                                      />
                                    </Link>
                                  </div>
                                  <button className="btnWishList">
                                    <img
                                      src={wishListIcon}
                                      alt="WishList"
                                    ></img>
                                    <img
                                      src={activeWishListIcon}
                                      alt="In WishList"
                                      style={{ display: "none" }}
                                    ></img>
                                  </button>
                                  <div className="content">
                                    <Link
                                      to={
                                        "/DetailedProduct/" +
                                        product.ProductName.replace(/ /g, "-") +
                                        "/" +
                                        product.ProductId
                                      }
                                    >
                                      <h5>{product.ProductName}</h5>
                                    </Link>
                                    <p className="sub">Playmegastore</p>
                                    {product.DefaultVariation.SalePrice ===
                                    0 ? (
                                      <p className="pricenormal">
                                        ${product.DefaultVariation.RegularPrice.toLocaleString()}{" "}
                                        <button
                                          onClick={() => handleAddCart(product)}
                                        >
                                          <img
                                            src={addcart}
                                            alt="Add to Cart"
                                          ></img>
                                        </button>
                                      </p>
                                    ) : (
                                      <p className="price">
                                        <span className="off">
                                          -
                                          {product.DefaultVariation.Percentage.toFixed(
                                            2
                                          ) + "%"}
                                        </span>
                                        <span className="was">
                                          <span className="fw-medium">
                                            Was:
                                          </span>{" "}
                                          <strike>
                                            $
                                            {
                                              product.DefaultVariation
                                                .RegularPrice.toLocaleString()
                                            }
                                          </strike>
                                        </span>
                                        ${product.DefaultVariation.SalePrice.toLocaleString()}{" "}
                                        <button
                                          onClick={() => handleAddCart(product)}
                                        >
                                          <img
                                            src={addcart}
                                            alt="Add to Cart"
                                          ></img>
                                        </button>
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : null}
            {categoryWidget == 3 ? (
              <>
                <div className="row">
                  <div className="col-12 col-md-12">
                    {widget5.map((w) => {
                      return (
                        <Swiper
                          className="productsSlider"
                          spaceBetween={50}
                          breakpoints={{
                            0: {
                              slidesPerView: 1,
                            },
                            500: {
                              slidesPerView: 1,
                            },
                            600: {
                              slidesPerView: 2,
                            },
                            800: {
                              slidesPerView: 2,
                            },
                            1100: {
                              slidesPerView: 3,
                            },
                            1200: {
                              slidesPerView: 3,
                            },
                            1300: {
                              slidesPerView: 4,
                            },
                          }}
                        >
                          {toprated.map((product) => {
                            return (
                              <SwiperSlide>
                                <div className="item" key={product.ProductId}>
                                  <div className="imgBox">
                                    <Link
                                      to={
                                        "/DetailedProduct/" +
                                        product.ProductName.replace(/ /g, "-") +
                                        "/" +
                                        product.ProductId
                                      }
                                    >
                                      <img
                                        src={
                                          Global.PHOTO_URL +
                                          product.DefaultVariation.Image1
                                        }
                                        alt={product.ProductName}
                                      />
                                    </Link>
                                  </div>
                                  <button className="btnWishList">
                                    <img
                                      src={wishListIcon}
                                      alt="WishList"
                                    ></img>
                                    <img
                                      src={activeWishListIcon}
                                      alt="In WishList"
                                      style={{ display: "none" }}
                                    ></img>
                                  </button>
                                  <div className="content">
                                    <Link
                                      to={
                                        "/DetailedProduct/" +
                                        product.ProductName.replace(/ /g, "-") +
                                        "/" +
                                        product.ProductId
                                      }
                                    >
                                      <h5>{product.ProductName}</h5>
                                    </Link>
                                    {product.DefaultVariation.SalePrice ===
                                    0 ? (
                                      <p className="pricenormal">
                                        ${product.DefaultVariation.RegularPrice.toLocaleString()}{" "}
                                        <button
                                          onClick={() => handleAddCart(product)}
                                        >
                                          <img
                                            src={addcart}
                                            alt="Add to Cart"
                                          ></img>
                                        </button>
                                      </p>
                                    ) : (
                                      <p className="price">
                                        <span className="off">
                                          -
                                          {product.DefaultVariation.Percentage.toFixed(
                                            2
                                          ) + "%"}
                                        </span>
                                        <span className="was">
                                          <span className="fw-medium">
                                            Was:
                                          </span>{" "}
                                          <strike>
                                            $
                                            {
                                              product.DefaultVariation
                                                .RegularPrice.toLocaleString()
                                            }
                                          </strike>
                                        </span>
                                        ${product.DefaultVariation.SalePrice.toLocaleString()}{" "}
                                        <button
                                          onClick={() => handleAddCart(product)}
                                        >
                                          <img
                                            src={addcart}
                                            alt="Add to Cart"
                                          ></img>
                                        </button>
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : null}
          </div>

          <div className="container-fluid mt-3">
            <div className="row">
              <div className="col-12 p-0">
                {slider2.map((first) => {
                  return (
                    <Swiper
                      className=""
                      spaceBetween={50}
                      slidesPerView={1}
                      autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                      }}
                    >
                      {first.slides.map((slide) => {
                        return (
                          <SwiperSlide>
                            <Link to={slide.Url}>
                              <img src={Global.PHOTO_URL + slide.SlideImage} />
                            </Link>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="container collectionSection">
            <div className="row titleBar">
              <div className="col-8 col-md-10">
                <h3>
                  {wName6.split(" ").length > 1 ? (
                    <>
                      <span>{wName6.split(" ")[0]}</span> {wName6.split(" ")[1]}
                    </>
                  ) : (
                    <span>{wName6}</span>
                  )}
                </h3>
              </div>
              {/* <div className="col-4 col-md-2 text-right">
                <Link to="/Collection">
                  View All
                  <span>
                    <img src={rightRedArrow} alt="Right Arrow" />
                  </span>
                </Link>
              </div> */}
            </div>
            <div className="row items d-none d-md-flex">
              {widget6.map((w) => {
                return (
                  <div
                    className="col-12 col-md-6 col-lg-4 col-xl-3 text-center"
                    key={w.CategoryId}
                  >
                    <Link
                      to={
                        "/Category/" +
                        w.CategoryName.replace(/ /g, "-") +
                        "/" +
                        w.CategoryId
                      }
                    >
                      <img
                        src={Global.PHOTO_URL + w.CategoryImage}
                        alt="Collection"
                      />
                      <p>{w.CategoryName}</p>
                    </Link>
                  </div>
                );
              })}
            </div>
            <div className="row items d-md-none">
              <div className="col-12">
                <div className="categoryScroll">
                  {widget6.map((w) => {
                    return (
                      <Link
                        to={
                          "/Category/" +
                          w.CategoryName.replace(/ /g, "-") +
                          "/" +
                          w.CategoryId
                        }
                      >
                        <img
                          src={Global.PHOTO_URL + w.CategoryImage}
                          alt="Collection"
                        />
                        <p>{w.CategoryName}</p>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="bestsellerSection">
            <div className="container">
              {widget7.map((w) => {
                return (
                  <div className="row titleBar">
                    <div className="col-8 col-md-10">
                      <h3>
                        {w.Title.split(" ").length > 1 ? (
                          <>
                            <span>{w.Title.split(" ")[0]}</span>{" "}
                            {w.Title.split(" ")[1]}
                          </>
                        ) : (
                          <span>{w.Title}</span>
                        )}
                      </h3>
                    </div>
                    <div className="col-4 col-md-2 text-right">
                      <Link
                        to={
                          "/Category/" +
                          w.CategoryName.replace(/ /g, "-") +
                          "/" +
                          w.CategoryId
                        }
                      >
                        View All
                        <span>
                          <img src={rightRedArrow} alt="Right Arrow" />
                        </span>
                      </Link>
                    </div>
                  </div>
                );
              })}
              <div className="row">
                <div className="col-12 col-md-7">
                  <div className="row">
                    {bestSeller.slice(0, 6).map((product) => {
                      return (
                        <div className="col-6 col-md-4" key={product.ProductId}>
                          <div className="item  position-relative">
                            <div className="imgBox">
                              <Link
                                to={
                                  "/DetailedProduct/" +
                                  product.ProductName.replace(/ /g, "-") +
                                  "/" +
                                  product.ProductId
                                }
                              >
                                <img
                                  src={
                                    Global.PHOTO_URL +
                                    product.DefaultVariation.Image1
                                  }
                                  alt={product.ProductName}
                                />
                              </Link>
                            </div>
                            <button className="btnWishList">
                              <img src={wishListIcon} alt="WishList"></img>
                              <img
                                src={activeWishListIcon}
                                alt="In WishList"
                                style={{ display: "none" }}
                              ></img>
                            </button>
                            <div className="content">
                              <Link
                                to={
                                  "/DetailedProduct/" +
                                  product.ProductName.replace(/ /g, "-") +
                                  "/" +
                                  product.ProductId
                                }
                              >
                                <h5>{product.ProductName}</h5>
                              </Link>
                              <p className="sub">Playmegastore</p>
                              {product.DefaultVariation.SalePrice === 0 ? (
                                <p className="pricenormal">
                                  ${product.DefaultVariation.RegularPrice.toLocaleString()}{" "}
                                  <button
                                    onClick={() => handleAddCart(product)}
                                  >
                                    <img src={addcart} alt="Add to Cart"></img>
                                  </button>
                                </p>
                              ) : (
                                <>
                                  <p className="price m-0">
                                    ${product.DefaultVariation.SalePrice.toLocaleString()}{" "}
                                    <button
                                      onClick={() => handleAddCart(product)}
                                    >
                                      <img
                                        src={addcart}
                                        alt="Add to Cart"
                                      ></img>
                                    </button>
                                  </p>
                                  <p className="price">
                                    <span className="off">
                                      -
                                      {product.DefaultVariation.Percentage.toFixed(
                                        2
                                      ) + "%"}
                                    </span>
                                    <span className="was">
                                      <span className="fw-medium">Was:</span>{" "}
                                      <strike>
                                        ${product.DefaultVariation.RegularPrice.toLocaleString()}
                                      </strike>
                                    </span>
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {bestSeller.slice(6, 7).map((product) => {
                  return (
                    <div className="col-12 col-md-5" key={product.ProductId}>
                      <div className="singleItem">
                        <Link
                          to={
                            "/DetailedProduct/" +
                            product.ProductName.replace(/ /g, "-") +
                            "/" +
                            product.ProductId
                          }
                        >
                          <img
                            src={
                              Global.PHOTO_URL + product.DefaultVariation.Image1
                            }
                            alt={product.ProductName}
                          />
                        </Link>
                        <div className="content">
                          <h3>
                            {product.ProductName} <small>- Playmegastore</small>
                          </h3>
                          {product.DefaultVariation.SalePrice === 0 ? (
                            <p className="pricenormal">
                              ${product.DefaultVariation.RegularPrice.toLocaleString()}{" "}
                              <button onClick={() => handleAddCart(product)}>
                                <img src={addcart} alt="Add to Cart"></img>
                              </button>
                            </p>
                          ) : (
                            <>
                              <p className="price m-0">
                                ${product.DefaultVariation.SalePrice.toLocaleString()}{" "}
                                <button onClick={() => handleAddCart(product)}>
                                  <img src={addcart} alt="Add to Cart"></img>
                                </button>
                              </p>
                              <p className="price">
                                <span className="off">
                                  -
                                  {product.DefaultVariation.Percentage.toFixed(
                                    2
                                  ) + "%"}
                                </span>
                                <span className="was">
                                  <span className="fw-medium">Was:</span>{" "}
                                  <strike>
                                    ${product.DefaultVariation.RegularPrice.toLocaleString()}
                                  </strike>
                                </span>
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="mobileNewsLetterSection d-md-none mt-3">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <img src={newsletter} alt="newsletter" />
                  <span>Sign Up To Our Newsletter</span>
                </div>
                <div className="col-12 form">
                  <input placeholder="Enter your email address" />
                  <button>Sign Up</button>
                </div>
              </div>
            </div>
          </div>
          <div className="container lowStock mb-3">
            {widget8.map((w) => {
              return (
                <div className="row titleBar">
                  <div className="col-8 col-md-10">
                    <h3>
                      {w.Title.split(" ").length > 2 ? (
                        <>
                          <span>
                            {w.Title.split(" ")[0] +
                              " " +
                              w.Title.split(" ")[1]}
                          </span>{" "}
                          {w.Title.split(" ")[2]}
                        </>
                      ) : (
                        <span>{w.Title}</span>
                      )}
                    </h3>
                  </div>
                  <div className="col-4 col-md-2 text-right">
                    <Link
                      to={
                        "/Category/" +
                        w.CategoryName.replace(/ /g, "-") +
                        "/" +
                        w.CategoryId
                      }
                    >
                      View All
                      <span>
                        <img src={rightRedArrow} alt="Right Arrow" />
                      </span>
                    </Link>
                  </div>
                </div>
              );
            })}
            <div className="row tabSection">
              {widget8.map((w) => {
                return (
                  <Swiper
                    className="productsSlider"
                    spaceBetween={50}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      500: {
                        slidesPerView: 1,
                      },
                      600: {
                        slidesPerView: 2,
                      },
                      800: {
                        slidesPerView: 2,
                      },
                      1100: {
                        slidesPerView: 3,
                      },
                      1200: {
                        slidesPerView: 3,
                      },
                      1300: {
                        slidesPerView: 4,
                      },
                    }}
                  >
                    {lowStock.map((product) => {
                      return (
                        <SwiperSlide>
                          <div className="item" key={product.ProductId}>
                            <div className="imgBox">
                              <Link
                                to={
                                  "/DetailedProduct/" +
                                  product.ProductName.replace(/ /g, "-") +
                                  "/" +
                                  product.ProductId
                                }
                              >
                                <img
                                  src={
                                    Global.PHOTO_URL +
                                    product.DefaultVariation.Image1
                                  }
                                  alt={product.ProductName}
                                />
                              </Link>
                            </div>
                            <button className="btnWishList">
                              <img src={wishListIcon} alt="WishList"></img>
                              <img
                                src={activeWishListIcon}
                                alt="In WishList"
                                style={{ display: "none" }}
                              ></img>
                            </button>
                            <div className="content">
                              <Link
                                to={
                                  "/DetailedProduct/" +
                                  product.ProductName.replace(/ /g, "-") +
                                  "/" +
                                  product.ProductId
                                }
                              >
                                <h5>{product.ProductName}</h5>
                              </Link>
                              <p className="sub">Playmegastore</p>
                              {product.DefaultVariation.SalePrice === 0 ? (
                                <p className="pricenormal">
                                  ${product.DefaultVariation.RegularPrice.toLocaleString()}{" "}
                                  <button
                                    onClick={() => handleAddCart(product)}
                                  >
                                    <img src={addcart} alt="Add to Cart"></img>
                                  </button>
                                </p>
                              ) : (
                                <p className="price">
                                  <span className="off">
                                    -
                                    {product.DefaultVariation.Percentage.toFixed(
                                      2
                                    ) + "%"}
                                  </span>
                                  <span className="was">
                                    <span className="fw-medium">Was:</span>{" "}
                                    <strike>
                                      ${product.DefaultVariation.RegularPrice.toLocaleString()}
                                    </strike>
                                  </span>
                                  ${product.DefaultVariation.SalePrice}{" "}
                                  <button
                                    onClick={() => handleAddCart(product)}
                                  >
                                    <img src={addcart} alt="Add to Cart"></img>
                                  </button>
                                </p>
                              )}
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Home;
