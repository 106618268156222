// import { Rating } from "react-simple-star-rating";
// import product1 from "../../images/products/product9.png";
// import thumb from "../../images/products/thumbs/thumb4.png";
// import thumb1 from "../../images/products/thumbs/thumb5.png";
// import thumb2 from "../../images/products/thumbs/thumb6.png";
// import { AiOutlinePlusCircle } from "react-icons/ai";

// import addcart from "../../images/icons/addcart.png";
// import rightRedArrow from "../../images/icons/rightRedArrow.png";

// import wishListIcon from "../../images/icons/wishlist.png";
// import activeWishListIcon from "../../images/icons/inWishList.png";

// import { Link, useNavigate, useParams } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import { FreeMode, Navigation, Thumbs } from "swiper";
// import "swiper/css/free-mode";
// import "swiper/css/navigation";
// import "swiper/css/thumbs";
// import { useEffect, useState } from "react";
// import { Global } from "../../Variables/Global";
// import axios from "axios";
// import Util from "../../Classes/Util";

// const DetailedProduct = (props) => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [details, setDetails] = useState({
//     ProductId: 0,
//     ProductName: "",
//     SmallDescription: "",
//   });
//   const [variations, setVariations] = useState([]);
//   const [imgArray, setImgArray] = useState([]);
//   const [thumbsSwiper, setThumbsSwiper] = useState(null);
//   const [crossSell, setCrossSell] = useState([]);
//   const [upSell, setUpSell] = useState([]);
//   const navigate = useNavigate();
//   const params = useParams();

//   const [product, setProduct] = useState("");
//   const getProductDetails = async () => {
//     var groupId;
//     if (localStorage.getItem("WishList")) {
//       groupId = 4;
//     } else {
//       groupId = 3;
//     }
//     const URL =
//       Global.API_URL +
//       "Products/GetProductByID/" +
//       params.ProductId +
//       "/-150/125/" +
//       groupId;
//     fetch(URL, {
//       method: "Get",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": Global.API_URL,
//       },
//     })
//       .then((resp) => {
//         if (resp.ok) {
//           return resp.json();
//         }
//       })
//       .then((result) => {
//         setProduct(result.Product);
//         setDetails(result.Product);
//         setVariations(result.ProductVariation);
//         var arr = [...imgArray];
//         if (result.ProductVariation[0].Image0 !== "") {
//           arr.push(result.ProductVariation[0].Image0);
//           setImgArray(arr);
//         }
//         if (result.ProductVariation[0].Image1 !== "") {
//           arr.push(result.ProductVariation[0].Image1);
//           setImgArray(arr);
//         }
//         if (result.ProductVariation[0].Image2 !== "") {
//           arr.push(result.ProductVariation[0].Image2);
//           setImgArray(arr);
//         }
//         if (result.ProductVariation[0].Image3 !== "") {
//           arr.push(result.ProductVariation[0].Image3);
//           setImgArray(arr);
//         }
//         if (result.ProductVariation[0].Image4 !== "") {
//           arr.push(result.ProductVariation[0].Image4);
//           setImgArray(arr);
//         }

//         const ColorArray = [];
//         result.ProductVariation.forEach((element) => {
//           if (element.AssignedAttributes.length > 0) {
//             element.AssignedAttributes.forEach((el) => {
//               if (el.AttributeId == 1 && parseInt(element.Quantity) > 0) {
//                 if (
//                   !ColorArray.some(
//                     (color) =>
//                       color.AttributeValueName === el.AttributeValueName
//                   )
//                 ) {
//                   ColorArray.push(el);
//                 }
//               }
//             });
//           }
//           // if (element.AssignedAttributes.length > 0) {
//           //   element.AssignedAttributes.forEach((el) => {
//           //     if (el.AttributeId == 2) {
//           //       if (
//           //         !SizeArray.some(
//           //           (color) =>
//           //             color.AttributeValueName === el.AttributeValueName
//           //         )
//           //       ) {
//           //         SizeArray.push(el);
//           //       }
//           //     }
//           //   });
//           // }
//           // if (ColorArray.length === 0) {
//           //   getSizes(prodvariation, "", element.ProductVariationId);
//           // }
//           //setMaxQuantity(element.Quantity);
//         });

//         if (ColorArray.length > 0) {
//           setColorList(ColorArray);
//         }

//       });
//   };

//   const getCrossSell = async () => {
//     var groupId;
//     if (localStorage.getItem("WishList")) {
//       groupId = 4;
//     } else {
//       groupId = 3;
//     }
//     const res = await axios.get("https://geolocation-db.com/json/");
//     var country;
//     fetch(
//       Global.API_URL +
//         "Country/GetCoutryByCountryCode/" +
//         res.data.country_code,
//       {
//         method: "GET",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         },
//       }
//     )
//       .then((Response) => {
//         if (Response.ok) {
//           return Response.json();
//         } else {
//           return "";
//         }
//       })
//       .then((Result) => {
//         country = Result.CountryId;
//         const offset = new Date().getTimezoneOffset();
//         const URL =
//           Global.API_URL +
//           "Products/GetCrossSells/" +
//           params.ProductId +
//           "/" +
//           offset +
//           "/" +
//           country +
//           "/" +
//           groupId;
//         fetch(URL, {
//           method: "Get",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             "Access-Control-Allow-Origin": Global.API_URL,
//           },
//         })
//           .then((resp) => {
//             if (resp.ok) {
//               return resp.json();
//             }
//           })
//           .then((result) => {
//             setCrossSell(result);
//           });
//       });
//   };
//   const getUpSell = async () => {
//     var groupId;
//     if (localStorage.getItem("WishList")) {
//       groupId = 4;
//     } else {
//       groupId = 3;
//     }
//     const res = await axios.get("https://geolocation-db.com/json/");
//     var country;
//     fetch(
//       Global.API_URL +
//         "Country/GetCoutryByCountryCode/" +
//         res.data.country_code,
//       {
//         method: "GET",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         },
//       }
//     )
//       .then((Response) => {
//         if (Response.ok) {
//           return Response.json();
//         } else {
//           return "";
//         }
//       })
//       .then((Result) => {
//         country = Result.CountryId;
//         const offset = new Date().getTimezoneOffset();
//         const URL =
//           Global.API_URL +
//           "Products/GetUpSells/" +
//           params.ProductId +
//           "/" +
//           offset +
//           "/" +
//           country +
//           "/" +
//           groupId;
//         fetch(URL, {
//           method: "Get",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             "Access-Control-Allow-Origin": Global.API_URL,
//           },
//         })
//           .then((resp) => {
//             if (resp.ok) {
//               return resp.json();
//             }
//           })
//           .then((result) => {
//             setUpSell(result);
//           });
//       });
//     setIsLoading(false);
//   };

//   const handleAttributeSelect = (attributeValueId) => {
//     console.log(attributeValueId);
//   };

//   const handleAddCart = () => {
//     var cartId = sessionStorage.getItem("GuestCart");
//     cartId = Util.decrypt(cartId);
//     var cartProduct = {
//       cartId: cartId,
//       productId: product.ProductId,
//       // variationId: product.DefaultVariation.ProductVariationId,
//       quantity: 1,
//     };

//     console.log(cartProduct);
//     var axios = require("axios");

//     var config = {
//       method: "post",
//       url: Global.API_URL + "Cart/AddItemToCart",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//       data: cartProduct,
//     };

//     axios(config)
//       .then(function (response) {
//         console.log(response);
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };

//   const [colorList, setColorList] = useState([]);
//   const [productColor, setProductColor] = useState("");
//   const [productDescription, setProductDescription] = useState("");

//   const getColor = (Color) => {
//     var variationId = 0;
//     variations.map((v) => {
//       v.AssignedAttributes.map((attribute) => {
//         if (
//           attribute.AttributeValueName === Color &&
//           attribute.AttributeId == 1 &&
//           parseInt(v.Quantity) > 0
//         ) {
//           variationId = v.ProductVariationId;
//           setProductColor(Color);
//           var ProductDescription = v.Description.replace("<p>", "").replace(
//             "</p>",
//             ""
//           );
//           setProductDescription(ProductDescription);
//         }
//       });
//     });
//     // getImages(Color, variationId);
//     // getSizes(productVariation, Color, variationId);
//   };

//   const [size, setSize] = useState([]);
//   const [sizeList, setSizeList] = useState([]);

//   useEffect(() => {
//     props.setHeaderTitle(params.ProductName);
//     props.setFooterActive(2);
//     window.scrollTo(0, 0);
//     getProductDetails();
//     getCrossSell();
//     getUpSell();
//   }, [params.ProductId]);
//   return (
//     <>
//       {isLoading ? (
//         <div className="spinner-container">
//           <div className="loading-spinner"></div>
//         </div>
//       ) : (
//         <>
//           <div className="container mt-60 detailedProduct">
//             <div className="row">
//               <div className="col-12 col-md-12">
//                 <ul className="bread-crumb">
//                   <li>Home</li>
//                   <li>/</li>
//                   <li>Detailed Product</li>
//                 </ul>
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-12 col-lg-5">
//                 {imgArray.length > 0 && (
//                   <>
//                     <div className="mainProductImageBox position-relative">
//                       <img src={Global.PHOTO_URL + imgArray[0]} />
//                     </div>

//                     <div className="row">
//                       <div className="col-12 col-md-4">
//                         <div className="productThumbBox">
//                           <img
//                             src={Global.PHOTO_URL + imgArray[1]}
//                             alt={details.ProductName}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-12 col-md-4">
//                         <div className="productThumbBox">
//                           <img
//                             src={Global.PHOTO_URL + imgArray[2]}
//                             alt={details.ProductName}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-12 col-md-4">
//                         <div className="productThumbBox">
//                           <img
//                             src={Global.PHOTO_URL + imgArray[3]}
//                             alt={details.ProductName}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                   </>
//                 )}
//               </div>
//               <div className="col-12 col-lg-7 productContent">
//                 <div className="row">
//                   <div className="col-12 col-md-12 mb-30">
//                     <h2>{details.ProductName}</h2>
//                   </div>
//                 </div>
//                 <div className="row mb-50">
//                   <div className="col-6">
//                     <span className="byMega">by Playmegastore</span>
//                   </div>
//                   {/* <div className="col-6 rateAmount">
//                     <Rating />
//                     <span className=" float-end">Rate: 2 / 5</span>
//                   </div> */}
//                 </div>
//                 <div className="row mb-50">
//                   <div className="col-12 col-md-12">
//                     <div
//                       className="description"
//                       dangerouslySetInnerHTML={{
//                         __html: details.SmallDescription,
//                       }}
//                     />
//                   </div>
//                 </div>
//                 {colorList.length > 0 && (
//                   <div className="attr-item">
//                     <span>Color: {productColor}</span>
//                     <ul className="colorPans">
//                       {colorList.map((e, i) => {
//                         return (
//                           <li
//                             className={
//                               e.AttributeValueName === productColor
//                                 ? "active"
//                                 : ""
//                             }
//                             onClick={() => getColor(e.AttributeValueName)}
//                           >
//                             <span
//                               style={{ backgroundColor: e.AttributeValue }}
//                             ></span>
//                           </li>
//                         );
//                       })}
//                     </ul>
//                   </div>
//                 )}
//                 {/* {variations.length > 0 && (
//                   <>
//                     <div className="">
//                       {variations[0].AssignedAttributes.map((attr) => {
//                         return (
//                           <>
//                             <div className="row mb-20">
//                               <div className="col-12 col-md-12">
//                                 <h5>
//                                   {attr.AttributeName}:{" "}
//                                   <small>{attr.AttributeValueName}</small>
//                                 </h5>
//                                 <ul>
//                                   {variations.map((vari,index) => {
//                                       console.log(vari)
//                                     // return(
//                                     //   // <li>{vari.AssignedAttributes[index].AttributeValueName}</li>
//                                     // )
//                                   })}
//                                 </ul>
//                               </div>
//                             </div>
//                           </>
//                         );
//                       })}
//                     </div>
//                   </>
//                 )} */}

//                 {/* {variations.map((u) => {
//                   return (
//                     <> */}
//                 {/* {variations[0].SalePrice !== 0 ? (
//                         <div className="row mb-50">
//                           <div className="col-8 col-md-6">
//                             <label className="oldPrice">
//                               Was:{" "}
//                               <strike>
//                                 ${variations[0].RegularPrice.toLocaleString()}
//                               </strike>
//                             </label>
//                             <span className="discount">
//                               -{variations[0].Percentage.toFixed(2)}%
//                             </span>
//                           </div>
//                           <div className="col-4 col-md-6 text-end">
//                             <label className="price">
//                               ${variations[0].SalePrice.toLocaleString()}
//                             </label>
//                           </div>
//                         </div>
//                       ) : (
//                         <div className="row mb-50">
//                           <div className="col-6">
//                             {/* <label className="oldPrice">Was: <strike>$1,200.00</strike></label>
//                         <span className="discount">-12%</span>
//                           </div>
//                           <div className="col-6 text-end">
//                             <label className="price">
//                               ${variations[0].RegularPrice.toLocaleString()}
//                             </label>
//                           </div>
//                         </div>
//                       )} */}
//                 {/* </>
//                   );
//                 })} */}
//                 <div className="row">
//                   <div className="col-8 col-md-10">
//                     <button className="cartBtn" onClick={() => handleAddCart()}>
//                       Add to Cart{" "}
//                       <span className="float-end">
//                         <AiOutlinePlusCircle />
//                       </span>
//                     </button>
//                   </div>
//                   <div className="col-4 col-md-2">
//                     <span className="quantity">Qty: </span>
//                     <input
//                       className="quantityBox"
//                       type="number"
//                       min="1"
//                       placeholder="1"
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="container lowStock mb-50">
//             <div className="row titleBar mt-0">
//               <div className="col-8 col-md-10">
//                 <h3>
//                   <span>You May</span> Also Like
//                 </h3>
//               </div>
//               <div className="col-4 col-md-2 text-right">
//                 <Link to="/">
//                   View All
//                   <span>
//                     <img src={rightRedArrow} alt="Right Arrow" />
//                   </span>
//                 </Link>
//               </div>
//             </div>
//             <div className="row tabSection">
//               <Swiper
//                 className="productsSlider"
//                 spaceBetween={50}
//                 breakpoints={{
//                   0: {
//                     slidesPerView: 1,
//                   },
//                   500: {
//                     slidesPerView: 1,
//                   },
//                   600: {
//                     slidesPerView: 2,
//                   },
//                   800: {
//                     slidesPerView: 2,
//                   },
//                   1100: {
//                     slidesPerView: 3,
//                   },
//                   1200: {
//                     slidesPerView: 3,
//                   },
//                   1300: {
//                     slidesPerView: 4,
//                   },
//                 }}
//               >
//                 {crossSell.map((product) => {
//                   return (
//                     <SwiperSlide>
//                       <div className="item" key={product.ProductId}>
//                         <div className="imgBox">
//                           <Link
//                             to={
//                               "/DetailedProduct/" +
//                               product.ProductName.replace(/ /g, "-") +
//                               "/" +
//                               product.ProductId
//                             }
//                           >
//                             <img
//                               src={
//                                 Global.PHOTO_URL +
//                                 product.DefaultVariation.Image1
//                               }
//                               alt={product.ProductName}
//                             />
//                           </Link>
//                         </div>
//                         <button className="btnWishList">
//                           <img src={wishListIcon} alt="WishList"></img>
//                           <img
//                             src={activeWishListIcon}
//                             alt="In WishList"
//                             style={{ display: "none" }}
//                           ></img>
//                         </button>
//                         <div className="content">
//                           <Link to={"/DetailedProduct/" + product.ProductId}>
//                             <h5>{product.ProductName}</h5>
//                           </Link>
//                           <p className="sub">Playmegastore</p>
//                           {product.DefaultVariation.SalePrice === 0 ? (
//                             <p className="pricenormal">
//                               ${product.DefaultVariation.RegularPrice}{" "}
//                               <button>
//                                 <img src={addcart} alt="Add to Cart"></img>
//                               </button>
//                             </p>
//                           ) : (
//                             <p className="price">
//                               <span className="off">
//                                 -
//                                 {product.DefaultVariation.Percentage.toFixed(
//                                   2
//                                 ) + "%"}
//                               </span>
//                               <span className="was">
//                                 <span className="fw-medium">Was:</span>{" "}
//                                 <strike>
//                                   ${product.DefaultVariation.RegularPrice}
//                                 </strike>
//                               </span>
//                               ${product.DefaultVariation.SalePrice}{" "}
//                               <button>
//                                 <img src={addcart} alt="Add to Cart"></img>
//                               </button>
//                             </p>
//                           )}
//                         </div>
//                       </div>
//                     </SwiperSlide>
//                   );
//                 })}
//               </Swiper>
//             </div>
//           </div>
//           <div className="container lowStock">
//             <div className="row titleBar mt-0">
//               <div className="col-8 col-md-9">
//                 <h3>
//                   <span>Explore </span> More
//                 </h3>
//               </div>
//               <div className="col-4 col-md-2 text-right">
//                 <Link to="/">
//                   View All
//                   <span>
//                     <img src={rightRedArrow} alt="Right Arrow" />
//                   </span>
//                 </Link>
//               </div>
//             </div>
//             <div className="row tabSection">
//               <Swiper
//                 className="productsSlider"
//                 spaceBetween={50}
//                 breakpoints={{
//                   0: {
//                     slidesPerView: 1,
//                   },
//                   500: {
//                     slidesPerView: 1,
//                   },
//                   600: {
//                     slidesPerView: 2,
//                   },
//                   800: {
//                     slidesPerView: 2,
//                   },
//                   1100: {
//                     slidesPerView: 3,
//                   },
//                   1200: {
//                     slidesPerView: 3,
//                   },
//                   1300: {
//                     slidesPerView: 4,
//                   },
//                 }}
//               >
//                 {upSell.map((product) => {
//                   return (
//                     <SwiperSlide>
//                       <div className="item" key={product.ProductId}>
//                         <div className="imgBox">
//                           <Link
//                             to={
//                               "/DetailedProduct/" +
//                               product.ProductName.replace(/ /g, "-") +
//                               "/" +
//                               product.ProductId
//                             }
//                           >
//                             <img
//                               src={
//                                 Global.PHOTO_URL +
//                                 product.DefaultVariation.Image1
//                               }
//                               alt={product.ProductName}
//                             />
//                           </Link>
//                         </div>
//                         <button className="btnWishList">
//                           <img src={wishListIcon} alt="WishList"></img>
//                           <img
//                             src={activeWishListIcon}
//                             alt="In WishList"
//                             style={{ display: "none" }}
//                           ></img>
//                         </button>
//                         <div className="content">
//                           <Link to={"/DetailedProduct/" + product.ProductId}>
//                             <h5>{product.ProductName}</h5>
//                           </Link>
//                           <p className="sub">Playmegastore</p>
//                           {product.DefaultVariation.SalePrice === 0 ? (
//                             <p className="pricenormal">
//                               $
//                               {product.DefaultVariation.RegularPrice.toLocaleString()}{" "}
//                               <button>
//                                 <img src={addcart} alt="Add to Cart"></img>
//                               </button>
//                             </p>
//                           ) : (
//                             <p className="price">
//                               <span className="off">
//                                 -
//                                 {product.DefaultVariation.Percentage.toFixed(
//                                   2
//                                 ) + "%"}
//                               </span>
//                               <span className="was">
//                                 <span className="fw-medium">Was:</span>{" "}
//                                 <strike>
//                                   $
//                                   {product.DefaultVariation.RegularPrice.toLocaleString()}
//                                 </strike>
//                               </span>
//                               $
//                               {product.DefaultVariation.SalePrice.toLocaleString()}{" "}
//                               <button>
//                                 <img src={addcart} alt="Add to Cart"></img>
//                               </button>
//                             </p>
//                           )}
//                         </div>
//                       </div>
//                     </SwiperSlide>
//                   );
//                 })}
//               </Swiper>
//             </div>
//           </div>
//         </>
//       )}
//     </>
//   );
// };
// export default DetailedProduct;

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { Global } from "../../Variables/Global";
import Util from "../../Classes/Util";

import addcart from "../../images/icons/addcart.png";
import rightRedArrow from "../../images/icons/rightRedArrow.png";

import wishListIcon from "../../images/icons/wishlist.png";
import activeWishListIcon from "../../images/icons/inWishList.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const DetailedProduct = (props) => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([
    { CategoryId: 0, CategoryName: "" },
  ]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [featuredUpSellProducts, setFeaturedUpSellProducts] = useState([]);
  const productOptions = {
    margin: 10,
    responsiveClass: true,
    dots: false,
    nav: false,
    loop: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  const [quantity, setQuantity] = useState(1);
  const [productColor, setProductColor] = useState("");
  const [productWarrantyAndShipping, setProductWarrantyAndShipping] =
    useState("");
  const [productWashAndCare, setProductWashAndCare] = useState("");
  const [productDeliveryAndReturns, setProductDeliveryAndReturns] =
    useState("");
  const [productSize, setProductSize] = useState("");
  const [productVariation, setProductVariation] = useState([]);
  const [variation, setVariation] = useState({
    ProductVariationId: 0,
    Quantity: 1,
    InCart: false,
    LowStockThreshold: -1,
  });
  const [inWishList, setInWishList] = useState(false);
  const [sku, setSKU] = useState("");
  const [productPrice, setProductPrice] = useState(0);
  const [productSale, setProductSale] = useState(0);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [imageNumber, setImageNumber] = useState(0);
  const [productName, setProductName] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productImages, setProductImages] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [size, setSize] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [tags, setTags] = useState([]);
  const [wishListItem, setWihListItem] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [maxQuantity, setMaxQuantity] = useState(0);
  const [productMaterial, setProductMaterial] = useState([]);
  const [sizeChart, setSizeChart] = useState({
    BrandId: 0,
    BrandName: "",
    IsUniversal: false,
    Notes: "",
    SizeChartValues: [],
    SizingAndFitGuide: "",
  });
  const [measurement, setMeasurement] = useState([]);
  const [sizeName, setSizeName] = useState([]);
  const [brand, setBrand] = useState({
    BrandId: 0,
    BrandName: "",
  });
  const myStyle1 = {
    backgroundImage: "url('" + Global.PHOTO_URL + productImages[0] + "')",
  };
  const myStyle2 = {
    backgroundImage: "url('" + Global.PHOTO_URL + productImages[1] + "')",
  };
  const myStyle3 = {
    backgroundImage: "url('" + Global.PHOTO_URL + productImages[2] + "')",
  };
  async function fetchProduct() {
    var groupId;
    if (localStorage.getItem("WishList")) {
      groupId = 4;
    } else {
      groupId = 3;
    }
    const res = await axios.get("https://geolocation-db.com/json/");
    var country;
    fetch(
      Global.API_URL +
        "Country/GetCoutryByCountryCode/" +
        res.data.country_code,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        } else {
          return "";
        }
      })
      .then((Result) => {
        country = Result.CountryId;
        const offset = new Date().getTimezoneOffset();
        fetch(
          Global.API_URL +
            "Products/GetProductByID/" +
            urlParams.ProductId +
            "/" +
            offset +
            "/" +
            country +
            "/" +
            groupId,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((Response) => {
            if (Response.ok) return Response.json();
          })
          .then((Result) => {
            var b = {
              BrandId: Result.BrandId,
              BrandName: Result.BrandName,
            };
            setBrand(b);
            var prodvariation = Result.ProductVariation;
            setProductVariation(prodvariation);
            var category = [];

            Result.AssignedCategories.map((c) => {
              const found = category.some(
                (el) => el.CategoryName === c.CategoryName
              );
              if (!found) {
                category.push({
                  CategoryId: c.CategoryId,
                  CategoryName: c.CategoryName,
                });
              }
            });
            setCategories(category);
            setProductName(Result.Product.ProductName);
            setProductDeliveryAndReturns(Result.Product.DeliveryAndReturns);
            setProductWarrantyAndShipping(Result.Product.WarrantyAndShipping);
            setProductWashAndCare(Result.Product.WashAndCare);
            var desc = Result.Product.SmallDescription.replaceAll("<p>", "")
              .replaceAll("</p>", "")
              .replaceAll("&nbsp;", " ");

            setShortDesc(desc);
            setTags(Result.AssignedTags);
            setProductMaterial(Result.AssignedMaterials);

            const ImageArray = [];
            const ColorArray = [];
            const SizeArray = [];
            var ProductDescription = Result.ProductVariation[0].Description;
            setProductDescription(ProductDescription);
            Result.ProductVariation.forEach((element) => {
              if (element.AssignedAttributes.length > 0) {
                element.AssignedAttributes.forEach((el) => {
                  if (el.AttributeId == 1 && parseInt(element.Quantity) > 0) {
                    if (
                      !ColorArray.some(
                        (color) =>
                          color.AttributeValueName === el.AttributeValueName
                      )
                    ) {
                      ColorArray.push(el);
                    }
                  }
                });
              }
              if (element.AssignedAttributes.length > 0) {
                element.AssignedAttributes.forEach((el) => {
                  if (el.AttributeId == 2) {
                    if (
                      !SizeArray.some(
                        (color) =>
                          color.AttributeValueName === el.AttributeValueName
                      )
                    ) {
                      SizeArray.push(el);
                    }
                  }
                });
              }
              if (ColorArray.length === 0) {
                getSizes(prodvariation, "", element.ProductVariationId);
              }
              setMaxQuantity(element.Quantity);
            });

            if (Result.ProductVariation[0].Image0 !== "") {
              ImageArray.push(Result.ProductVariation[0].Image0);
            }
            if (Result.ProductVariation[0].Image1 !== "") {
              ImageArray.push(Result.ProductVariation[0].Image1);
            }
            if (Result.ProductVariation[0].Image2 !== "") {
              ImageArray.push(Result.ProductVariation[0].Image2);
            }
            if (Result.ProductVariation[0].Image3 !== "") {
              ImageArray.push(Result.ProductVariation[0].Image3);
            }
            if (Result.ProductVariation[0].Image4 !== "") {
              ImageArray.push(Result.ProductVariation[0].Image4);
            }
            if (Result.ProductVariation[0].AssignedAttributes.length > 0) {
              Result.ProductVariation[0].AssignedAttributes.forEach((el) => {
                if (
                  el.AttributeId === 1 &&
                  parseInt(Result.ProductVariation[0].Quantity) > 0
                ) {
                  getImages(
                    el.AttributeValueName,
                    Result.ProductVariation[0].ProductVariationId
                  );
                  setProductColor(el.AttributeValueName);
                  getSizes(
                    prodvariation,
                    el.AttributeValueName,
                    Result.ProductVariation[0].ProductVariationId
                  );
                }
              });
            }
            setVariation((prevState) => ({
              ...prevState,
              ProductVariationId: Result.ProductVariation[0].ProductVariationId,
              Quantity: quantity,
              LowStockThreshold: Result.ProductVariation[0].LowStockThreshold,
            }));

            if (ColorArray.length > 0) {
              setColorList(ColorArray);
            }
            if (ImageArray.length > 0) {
              setProductImages(ImageArray);
              setImageNumber(ImageArray.length);
            }
            if (SizeArray.length > 0) {
              setSizeList(SizeArray);
            }
          });
      });
  }

  const getSizes = (prodvariation, Color, ProductVariationId) => {
    var Size = [];
    var quantity = variation.Quantity;

    var prodvariations = [];
    prodvariation.map((v) => {
      v.AssignedAttributes.map((attribute) => {
        if (
          attribute.AttributeValueName === Color &&
          parseInt(attribute.AttributeId) === 1
        ) {
          prodvariations.push(v.ProductVariationId);
          v.AssignedAttributes.map((size) => {
            if (parseInt(size.AttributeId) === 2 && parseInt(v.Quantity) > 0) {
              Size.push({
                Sizes: size,
                variationId: v.ProductVariationId,
                Quantity: quantity,
                max: v.Quantity,
                LowStockThreshold: v.LowStockThreshold,
              });

              setVariation((prevState) => ({
                ...prevState,
                ProductVariationId: prodvariations[0],
                LowStockThreshold: prodvariation[0].LowStockThreshold,
              }));
            } else {
              setVariation((prevState) => ({
                ...prevState,
                ProductVariationId: prodvariations[0],
                LowStockThreshold: prodvariation[0].LowStockThreshold,
              }));
            }
          });
          getPrice(prodvariation, Color, prodvariations[0]);
          getSku(prodvariation, prodvariations[0]);
        } else if (Color === "") {
          prodvariations.push(v.ProductVariationId);
          v.AssignedAttributes.map((size) => {
            if (parseInt(size.AttributeId) === 2 && parseInt(v.Quantity) > 0) {
              Size.push({
                Sizes: size,
                variationId: v.ProductVariationId,
                Quantity: quantity,
                max: v.Quantity,
                LowStockThreshold: v.LowStockThreshold,
              });
              getPrice(prodvariation, Color, prodvariations[0]);
              getSku(prodvariation, prodvariations[0]);
              setVariation((prevState) => ({
                ...prevState,
                ProductVariationId: prodvariations[0],
                LowStockThreshold: v.LowStockThreshold,
              }));
            }
          });
        }
      });
    });
    if (Size.length > 0) {
      const uniqueIds = [];

      const unique = Size.filter((element) => {
        const isDuplicate = uniqueIds.includes(element.variationId);

        if (!isDuplicate) {
          uniqueIds.push(element.variationId);

          return true;
        }

        return false;
      });

      setSize(unique);
      setProductSize(Size[0].Sizes.AttributeValueName);
    }
  };

  const getImages = (Color, ProductVariationId) => {
    var count = 0;
    var Images = [];
    productVariation.map((v) => {
      if (parseInt(v.ProductVariationId) === parseInt(ProductVariationId)) {
        v.AssignedAttributes.map((attribute) => {
          if (
            attribute.AttributeValueName === Color &&
            attribute.AttributeId === 1
          ) {
            if (v.Image0 !== "") {
              Images.push(v.Image0);
              count += 1;
            }
            if (v.Image1 !== "") {
              Images.push(v.Image1);
              count += 1;
            }
            if (v.Image2 !== "") {
              Images.push(v.Image2);
              count += 1;
            }
            if (v.Image3 !== "") {
              Images.push(v.Image3);
              count += 1;
            }
            if (v.Image4 !== "") {
              Images.push(v.Image4);
              count += 1;
            }
          }
        });
      }
    });
    setImageNumber(count);
    setProductImages(Images);
  };
  const getSku = (prodvariation, ProductVariationId) => {
    var sku = "";
    prodvariation.map((v) => {
      if (parseInt(v.ProductVariationId) === parseInt(ProductVariationId)) {
        sku = v.Sku;
      }
    });
    setSKU(sku);
  };
  const getPrice = (prodvariation, Color, ProductVariationId) => {
    var price = 0;
    var salePrice = 0;
    prodvariation.map((v) => {
      if (parseInt(v.ProductVariationId) === parseInt(ProductVariationId)) {
        v.AssignedAttributes.map((attribute) => {
          price = v.RegularPrice;
          salePrice = v.SalePrice;
        });
      }
    });
    setProductPrice(price);
    setProductSale(salePrice);
  };
  const getColor = (Color) => {
    var variationId = 0;
    productVariation.map((v) => {
      v.AssignedAttributes.map((attribute) => {
        if (
          attribute.AttributeValueName === Color &&
          attribute.AttributeId == 1 &&
          parseInt(v.Quantity) > 0
        ) {
          variationId = v.ProductVariationId;
          setProductColor(Color);
          var ProductDescription = v.Description.replace("<p>", "").replace(
            "</p>",
            ""
          );
          setProductDescription(ProductDescription);
        }
      });
    });
    getImages(Color, variationId);
    getSizes(productVariation, Color, variationId);
  };
  const AddToCart = () => {
    if (localStorage.getItem("Cart") !== null) {
      var CartID = Util.decrypt(localStorage.getItem("Cart"));

      fetch(Global.API_URL + "Cart/AddItemToCart", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cartId: CartID,
          productId: urlParams.ProductId,
          variationId: variation.ProductVariationId,
          quantity: variation.Quantity,
        }),
      })
        .then((Response) => {
          if (Response.ok) {
            return Response.json();
          }
        })
        .then((Result) => {
          setVariation((prevState) => ({
            ...prevState,
            InCart: true,
          }));
          GetGuestCartItem(CartID);
        });
    } else {
      var CartID = Util.decrypt(sessionStorage.getItem("GuestCart"));

      fetch(Global.API_URL + "Cart/AddItemToCart", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cartId: CartID,
          productId: urlParams.ProductId,
          variationId: variation.ProductVariationId,
          quantity: variation.Quantity,
        }),
      })
        .then((Response) => {
          if (Response.ok) {
            return Response.json();
          }
        })
        .then((Result) => {
          setVariation((prevState) => ({
            ...prevState,
            InCart: true,
          }));
          GetGuestCartItem(CartID);
        });
    }
  };
  const AddItemToCart = (ProductId, ProductVariationId) => {
    if (localStorage.getItem("Cart") !== null) {
      var CartID = Util.decrypt(localStorage.getItem("Cart"));

      fetch(Global.API_URL + "Cart/AddItemToCart", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cartId: CartID,
          productId: ProductId,
          variationId: ProductVariationId,
          quantity: 1,
        }),
      })
        .then((Response) => {
          if (Response.ok) {
            return Response.json();
          }
        })
        .then((Result) => {
          GetGuestCartItem(CartID);
        });
    } else {
      var CartID = Util.decrypt(sessionStorage.getItem("GuestCart"));
      fetch(Global.API_URL + "Cart/AddItemToCart", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cartId: CartID,
          productId: ProductId,
          variationId: ProductVariationId,
          quantity: 1,
        }),
      })
        .then((Response) => {
          if (Response.ok) {
            return Response.json();
          }
        })
        .then((Result) => {
          GetGuestCartItem(CartID);
        });
    }
  };

  const GetGuestCartItem = async () => {
    var groupId;
    if (localStorage.getItem("WishList")) {
      groupId = 4;
    } else {
      groupId = 3;
    }
    const res = await axios.get("https://geolocation-db.com/json/");
    var country;
    fetch(
      Global.API_URL +
        "Country/GetCoutryByCountryCode/" +
        res.data.country_code,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        } else {
          return "";
        }
      })
      .then((Result) => {
        country = Result.CountryId;
        var CartID;
        if (localStorage.getItem("Cart")) {
          CartID = Util.decrypt(localStorage.getItem("Cart"));
        } else {
          CartID = Util.decrypt(sessionStorage.getItem("GuestCart"));
        }
        const offset = new Date().getTimezoneOffset();
        fetch(
          Global.API_URL +
            "Cart/GetCartItems/" +
            CartID +
            "/" +
            offset +
            "/" +
            groupId +
            "/" +
            country,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((Response) => {
            if (Response.ok) {
              return Response.json();
            }
          })
          .then((Result) => {
            var count = Result.Count;
            props.setCartCount(count);
            props.setCartItem(Result.CartItems);
          });
      });
  };
  const AddToWishList = () => {
    if (localStorage.getItem("WishList") !== null) {
      var WishListId = Util.decrypt(localStorage.getItem("WishList"));
      if (!inWishList) {
        fetch(Global.API_URL + "WishList/AddItemToWishList", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            wishlistId: WishListId,
            variationId: variation.ProductVariationId,
            productId: urlParams.ProductId,
          }),
        }).then((Response) => {
          if (Response.ok) {
            setTimeout(() => {
              CheckWishList();
            }, 400);
          }
        });
      } else {
        fetch(
          Global.API_URL +
            "Wishlist/RemoveItemFromWishlist/" +
            urlParams.ProductId +
            "/" +
            WishListId,
          {
            method: "Delete",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        ).then((Response) => {
          if (Response.ok) {
            setTimeout(() => {
              CheckWishList();
            }, 400);
          }
        });
      }
    } else {
      var WishListId = Util.decrypt(sessionStorage.getItem("GuestWishList"));
      if (!inWishList) {
        fetch(Global.API_URL + "WishList/AddItemToWishList", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            wishlistId: WishListId,
            variationId: variation.ProductVariationId,
            productId: urlParams.ProductId,
          }),
        }).then((Response) => {
          if (Response.ok) {
            setTimeout(() => {
              CheckWishList();
            }, 400);
          }
        });
      } else {
        fetch(
          Global.API_URL +
            "Wishlist/RemoveItemFromWishlist/" +
            urlParams.ProductId +
            "/" +
            WishListId,
          {
            method: "Delete",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        ).then((Response) => {
          if (Response.ok) {
            setTimeout(() => {
              CheckWishList();
            }, 400);
          }
        });
      }
    }
  };

  const AddQuantity = () => {
    var q = variation.Quantity;
    var sizesVariation = [...size];
    var maximum;
    sizesVariation.forEach((s) => {
      if (parseInt(s.variationId) === parseInt(variation.ProductVariationId)) {
        maximum = s.max;
        setMaxQuantity(maximum);
      }
    });

    q++;
    if (parseInt(q) > parseInt(maximum)) {
      q = maximum;
      if (parseInt(maximum) > 1) {
        toast.info(maximum + " items available from this products");
      } else {
        toast.info(maximum + " item available from this products");
      }
    }
    setVariation((prevState) => ({
      ...prevState,
      Quantity: q,
    }));
  };

  const SubtractQuantity = () => {
    var q = variation.Quantity;
    q--;
    if (q <= 0) {
      q = 1;
    }
    setVariation((prevState) => ({
      ...prevState,
      Quantity: q,
    }));
  };
  const CheckWishList = async () => {
    var groupId;
    if (localStorage.getItem("WishList")) {
      groupId = 4;
    } else {
      groupId = 3;
    }
    const res = await axios.get("https://geolocation-db.com/json/");
    var country;
    fetch(
      Global.API_URL +
        "Country/GetCoutryByCountryCode/" +
        res.data.country_code,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        } else {
          return "";
        }
      })
      .then((Result) => {
        country = Result.CountryId;
        const offset = new Date().getTimezoneOffset();
        if (localStorage.getItem("WishList") !== null) {
          var WishListId = Util.decrypt(localStorage.getItem("WishList"));
          fetch(
            Global.API_URL +
              "Wishlist/GetWishistItems/" +
              WishListId +
              "/" +
              offset +
              "/" +
              country +
              "/" +
              groupId,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
            .then((Response) => {
              if (Response.ok) {
                return Response.json();
              }
            })
            .then((Result) => {
              props.setWishListCount(Result.Count);
              if (Result.WishlistItems.length > 0) {
                Result.WishlistItems.map((item) => {
                  if (
                    parseInt(item.ProductId) === parseInt(urlParams.ProductId)
                  ) {
                    setInWishList(true);
                  }
                });
              } else {
                setInWishList(false);
              }
            });
        } else {
          var WishListId = Util.decrypt(
            sessionStorage.getItem("GuestWishList")
          );
          fetch(
            Global.API_URL +
              "Wishlist/GetWishistItems/" +
              WishListId +
              "/" +
              offset +
              "/" +
              country +
              "/" +
              groupId,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
            .then((Response) => {
              if (Response.ok) {
                return Response.json();
              }
            })
            .then((Result) => {
              props.setWishListCount(Result.Count);

              if (Result.WishlistItems.length > 0) {
                Result.WishlistItems.map((item) => {
                  if (
                    parseInt(item.ProductId) === parseInt(urlParams.ProductId)
                  ) {
                    setInWishList(true);
                  }
                });
              } else {
                setInWishList(false);
              }
            });
        }
      });
  };
  // const RemoveFromWishList = (ProductId, ProductVariationId) => {
  //   if (localStorage.getItem("WishList") !== null) {
  //     var WishListId = Util.decrypt(localStorage.getItem("WishList"));

  //     fetch(
  //       Global.API_URL +
  //         "Wishlist/RemoveItemFromWishlist/" +
  //         ProductId +
  //         "/" +
  //         WishListId,
  //       {
  //         method: "Delete",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     ).then((Response) => {
  //       if (Response.ok) {
  //         var wishListCount = props.wishListCount;
  //         wishListCount--;
  //         props.setWishListCount(wishListCount);
  //         setTimeout(() => {
  //           CheckWishListItem();
  //         }, 400);
  //       }
  //     });
  //   } else {
  //     var WishListId = Util.decrypt(sessionStorage.getItem("GuestWishList"));

  //     fetch(
  //       Global.API_URL +
  //         "Wishlist/RemoveItemFromWishlist/" +
  //         ProductId +
  //         "/" +
  //         WishListId,
  //       {
  //         method: "Delete",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     ).then((Response) => {
  //       if (Response.ok) {
  //         var wishListCount = props.wishListCount;
  //         wishListCount--;
  //         props.setWishListCount(wishListCount);
  //         setTimeout(() => {
  //           CheckWishListItem();
  //         }, 400);
  //       }
  //     });
  //   }
  // };
  // const AddItemToWishList = (ProductId, ProductVariationId) => {
  //   if (localStorage.getItem("WishList") !== null) {
  //     var WishListId = Util.decrypt(localStorage.getItem("WishList"));
  //     fetch(Global.API_URL + "WishList/AddItemToWishList", {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         wishlistId: WishListId,
  //         variationId: ProductVariationId,
  //         productId: ProductId,
  //       }),
  //     }).then((Response) => {
  //       if (Response.ok) {
  //         var wishListCount = props.wishListCount;
  //         wishListCount++;
  //         props.setWishListCount(wishListCount);
  //         setTimeout(() => {
  //           CheckWishListItem();
  //         }, 400);
  //       }
  //     });
  //   } else {
  //     var WishListId = Util.decrypt(sessionStorage.getItem("GuestWishList"));
  //     fetch(Global.API_URL + "WishList/AddItemToWishList", {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         wishlistId: WishListId,
  //         variationId: ProductVariationId,
  //         productId: ProductId,
  //       }),
  //     }).then((Response) => {
  //       if (Response.ok) {
  //         var wishListCount = props.wishListCount;
  //         wishListCount++;
  //         props.setWishListCount(wishListCount);
  //         setTimeout(() => {
  //           CheckWishListItem();
  //         }, 400);
  //       }
  //     });
  //   }
  // };
  // const CheckWishListItem = async () => {
  //   var groupId;
  //   if (localStorage.getItem("WishList")) {
  //     groupId = 4;
  //   } else {
  //     groupId = 3;
  //   }
  //   const res = await axios.get("https://geolocation-db.com/json/");
  //   var country;
  //   fetch(
  //     Global.API_URL +
  //       "Country/GetCoutryByCountryCode/" +
  //       res.data.country_code,
  //     {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   )
  //     .then((Response) => {
  //       if (Response.ok) {
  //         return Response.json();
  //       } else {
  //         return "";
  //       }
  //     })
  //     .then((Result) => {
  //       country = Result.CountryId;
  //       const offset = new Date().getTimezoneOffset();
  //       var array = [...featuredProducts];
  //       var nArray = [...featuredUpSellProducts];
  //       if (localStorage.getItem("WishList") !== null) {
  //         var WishListId = Util.decrypt(localStorage.getItem("WishList"));
  //         let wishlist = [],
  //           newArray = [],
  //           foundInWishlist = false;
  //         fetch(
  //           Global.API_URL +
  //             "Wishlist/GetWishistItems/" +
  //             WishListId +
  //             "/" +
  //             offset +
  //             "/" +
  //             country +
  //             "/" +
  //             groupId,
  //           {
  //             method: "GET",
  //             headers: {
  //               Accept: "application/json",
  //               "Content-Type": "application/json",
  //             },
  //           }
  //         )
  //           .then((Response) => {
  //             if (Response.ok) {
  //               return Response.json();
  //             }
  //           })
  //           .then((Result) => {
  //             if (Result.WishlistItems.length > 0) {
  //               array.forEach((product) => {
  //                 foundInWishlist = false;
  //                 Result.WishlistItems.forEach((item) => {
  //                   if (
  //                     parseInt(item.ProductId) === parseInt(product.ProductId)
  //                   ) {
  //                     foundInWishlist = true;
  //                     newArray.push({
  //                       ProductId: product.ProductId,
  //                       IsInWishlist: foundInWishlist,
  //                     });
  //                   }
  //                 });
  //               });
  //               nArray.forEach((product) => {
  //                 foundInWishlist = false;
  //                 Result.WishlistItems.forEach((item) => {
  //                   if (
  //                     parseInt(item.ProductId) === parseInt(product.ProductId)
  //                   ) {
  //                     foundInWishlist = true;
  //                     newArray.push({
  //                       ProductId: product.ProductId,
  //                       IsInWishlist: foundInWishlist,
  //                     });
  //                   }
  //                 });
  //               });
  //               setWihListItem(newArray);
  //             } else {
  //               setWihListItem(newArray);
  //             }
  //           });
  //       } else {
  //         var WishListId = Util.decrypt(
  //           sessionStorage.getItem("GuestWishList")
  //         );
  //         let wishlist = [],
  //           newArray = [],
  //           foundInWishlist = false;
  //         fetch(
  //           Global.API_URL +
  //             "Wishlist/GetWishistItems/" +
  //             WishListId +
  //             "/" +
  //             offset +
  //             "/" +
  //             country +
  //             "/" +
  //             groupId,
  //           {
  //             method: "GET",
  //             headers: {
  //               Accept: "application/json",
  //               "Content-Type": "application/json",
  //             },
  //           }
  //         )
  //           .then((Response) => {
  //             if (Response.ok) {
  //               return Response.json();
  //             }
  //           })
  //           .then((Result) => {
  //             if (Result.WishlistItems.length > 0) {
  //               array.forEach((product) => {
  //                 foundInWishlist = false;
  //                 Result.WishlistItems.forEach((item) => {
  //                   if (
  //                     parseInt(item.ProductId) === parseInt(product.ProductId)
  //                   ) {
  //                     foundInWishlist = true;
  //                     newArray.push({
  //                       ProductId: product.ProductId,
  //                       IsInWishlist: foundInWishlist,
  //                     });
  //                   }
  //                 });
  //               });
  //               nArray.forEach((product) => {
  //                 foundInWishlist = false;
  //                 Result.WishlistItems.forEach((item) => {
  //                   if (
  //                     parseInt(item.ProductId) === parseInt(product.ProductId)
  //                   ) {
  //                     foundInWishlist = true;
  //                     newArray.push({
  //                       ProductId: product.ProductId,
  //                       IsInWishlist: foundInWishlist,
  //                     });
  //                   }
  //                 });
  //               });
  //               setWihListItem(newArray);
  //             } else {
  //               setWihListItem(newArray);
  //             }
  //           });
  //       }
  //     });
  // };
  const fetchUpSellProduct = async () => {
    var groupId;
    if (localStorage.getItem("WishList")) {
      groupId = 4;
    } else {
      groupId = 3;
    }
    const res = await axios.get("https://geolocation-db.com/json/");
    var country;
    fetch(
      Global.API_URL +
        "Country/GetCoutryByCountryCode/" +
        res.data.country_code,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        } else {
          return "";
        }
      })
      .then((Result) => {
        country = Result.CountryId;
        const offset = new Date().getTimezoneOffset();
        fetch(
          Global.API_URL +
            "Products/GetCrossSells/" +
            urlParams.ProductId +
            "/" +
            offset +
            "/" +
            country +
            "/" +
            groupId,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((Response) => {
            if (Response.ok) {
              return Response.json();
            }
          })
          .then((Result) => {
            var nArray = Result;
            if (localStorage.getItem("WishList") !== null) {
              var WishListId = Util.decrypt(localStorage.getItem("WishList"));
              let wishlist = [],
                newArray = [],
                foundInWishlist = false;
              fetch(
                Global.API_URL +
                  "Wishlist/GetWishistItems/" +
                  WishListId +
                  "/" +
                  offset +
                  "/" +
                  country +
                  "/" +
                  groupId,
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                }
              )
                .then((Response) => {
                  if (Response.ok) {
                    return Response.json();
                  }
                })
                .then((Result) => {
                  if (Result.WishlistItems.length > 0) {
                    nArray.forEach((product) => {
                      foundInWishlist = false;
                      Result.WishlistItems.forEach((item) => {
                        if (
                          parseInt(item.ProductId) ===
                          parseInt(product.ProductId)
                        ) {
                          foundInWishlist = true;
                          newArray.push({
                            ProductId: product.ProductId,
                            IsInWishlist: foundInWishlist,
                          });
                        }
                      });
                    });
                    setWihListItem(newArray);
                  } else {
                    setWihListItem(newArray);
                  }
                });
            } else {
              var WishListId = Util.decrypt(
                sessionStorage.getItem("GuestWishList")
              );
              let wishlist = [],
                newArray = [],
                foundInWishlist = false;
              fetch(
                Global.API_URL +
                  "Wishlist/GetWishistItems/" +
                  WishListId +
                  "/" +
                  offset +
                  "/" +
                  country +
                  "/" +
                  groupId,
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                }
              )
                .then((Response) => {
                  if (Response.ok) {
                    return Response.json();
                  }
                })
                .then((Result) => {
                  if (Result.WishlistItems.length > 0) {
                    nArray.forEach((product) => {
                      foundInWishlist = false;
                      Result.WishlistItems.forEach((item) => {
                        if (
                          parseInt(item.ProductId) ===
                          parseInt(product.ProductId)
                        ) {
                          foundInWishlist = true;
                          newArray.push({
                            ProductId: product.ProductId,
                            IsInWishlist: foundInWishlist,
                          });
                        }
                      });
                    });

                    setWihListItem(newArray);
                  } else {
                    setWihListItem(newArray);
                  }
                });
            }
            setFeaturedUpSellProducts(nArray);
          });
      });
  };
  const fetchCrosssSellProduct = async () => {
    var groupId;
    if (localStorage.getItem("WishList")) {
      groupId = 4;
    } else {
      groupId = 3;
    }
    const res = await axios.get("https://geolocation-db.com/json/");
    var country;
    fetch(
      Global.API_URL +
        "Country/GetCoutryByCountryCode/" +
        res.data.country_code,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        } else {
          return "";
        }
      })
      .then((Result) => {
        country = Result.CountryId;
        const offset = new Date().getTimezoneOffset();
        fetch(
          Global.API_URL +
            "Products/GetUpSells/" +
            urlParams.ProductId +
            "/" +
            offset +
            "/" +
            country +
            "/" +
            groupId,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((Response) => {
            if (Response.ok) {
              return Response.json();
            }
          })
          .then((Result) => {
            var nArray = Result;
            if (localStorage.getItem("WishList") !== null) {
              var WishListId = Util.decrypt(localStorage.getItem("WishList"));
              let wishlist = [],
                newArray = [],
                foundInWishlist = false;
              fetch(
                Global.API_URL +
                  "Wishlist/GetWishistItems/" +
                  WishListId +
                  "/" +
                  offset +
                  "/" +
                  country +
                  "/" +
                  groupId,
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                }
              )
                .then((Response) => {
                  if (Response.ok) {
                    return Response.json();
                  }
                })
                .then((Result) => {
                  if (Result.WishlistItems.length > 0) {
                    nArray.forEach((product) => {
                      foundInWishlist = false;
                      Result.WishlistItems.forEach((item) => {
                        if (
                          parseInt(item.ProductId) ===
                          parseInt(product.ProductId)
                        ) {
                          foundInWishlist = true;
                          newArray.push({
                            ProductId: product.ProductId,
                            IsInWishlist: foundInWishlist,
                          });
                        }
                      });
                    });
                    setWihListItem(newArray);
                  } else {
                    setWihListItem(newArray);
                  }
                });
            } else {
              var WishListId = Util.decrypt(
                sessionStorage.getItem("GuestWishList")
              );
              let wishlist = [],
                newArray = [],
                foundInWishlist = false;
              fetch(
                Global.API_URL +
                  "Wishlist/GetWishistItems/" +
                  WishListId +
                  "/" +
                  offset +
                  "/" +
                  country +
                  "/" +
                  groupId,
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                }
              )
                .then((Response) => {
                  if (Response.ok) {
                    return Response.json();
                  }
                })
                .then((Result) => {
                  if (Result.WishlistItems.length > 0) {
                    nArray.forEach((product) => {
                      foundInWishlist = false;
                      Result.WishlistItems.forEach((item) => {
                        if (
                          parseInt(item.ProductId) ===
                          parseInt(product.ProductId)
                        ) {
                          foundInWishlist = true;
                          newArray.push({
                            ProductId: product.ProductId,
                            IsInWishlist: foundInWishlist,
                          });
                        }
                      });
                    });

                    setWihListItem(newArray);
                  } else {
                    setWihListItem(newArray);
                  }
                });
            }
            setFeaturedProducts(nArray);
          });
      });
  };
  const getVariation = (s, variationId, LowStockThreshold) => {
    setProductSize(s);
    getPrice(productVariation, productColor, variationId);
    getSku(productVariation, variationId);
    setVariation((prevState) => ({
      ...prevState,
      ProductVariationId: variationId,
      Quantity: variation.Quantity,
      LowStockThreshold: LowStockThreshold,
    }));
  };

  const ChangeIamge = (index) => {
    setSliderIndex(index);
  };

  const handleQuantityChange = (qty) => {
    if (qty > variation.Quantity) {
      AddQuantity();
    } else {
      SubtractQuantity();
    }
  };

  useEffect(() => {
    fetchProduct();
    fetchUpSellProduct();
    fetchCrosssSellProduct();
    CheckWishList();
    GetGuestCartItem();
  }, [urlParams.ProductId, urlParams.CategoryId]);

  return (
    <>
      <div className="container mt-60 detailedProduct">
        <div className="row">
          <div className="col-12 col-md-12">
            <ul className="bread-crumb">
              <li>Home</li>
              <li>/</li>
              <li>{productName}</li>
            </ul>
          </div>
        </div>
        <div className="container product-detail">
          <div className="row">
            <div className="col-12 col-lg-5">
              {productImages.length > 0 && (
                <>
                  <div className="mainProductImageBox position-relative">
                    <img src={Global.PHOTO_URL + productImages[sliderIndex]} />
                  </div>

                  <div className="row">
                    {productImages.slice(0, 3).map((e, index) => {
                      return (
                        <div className="col-12 col-md-4">
                          <div className="productThumbBox">
                            <img
                              src={Global.PHOTO_URL + e}
                              onClick={() => ChangeIamge(index)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
            <div className="col-12 col-lg-7 productContent">
              <div className="row">
                <div className="col-12 col-md-12 mb-30">
                  <h2>{productName}</h2>
                </div>
              </div>
              {brand.BrandName != "" && (
                <div className="row mb-50">
                  <div className="col-6">
                    <span className="byMega">by {brand.BrandName}</span>
                  </div>
                  {/* <div className="col-6 rateAmount">
                     <Rating />
                     <span className=" float-end">Rate: 2 / 5</span>
                   </div> */}
                </div>
              )}
              <div className="row mb-50">
                <div className="col-12 col-md-12">
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: shortDesc,
                    }}
                  />
                </div>
              </div>
              {colorList.length > 0 && (
                <div className="row mb-20">
                  <div className="col-12 col-md-12">
                    <div className="attr-item">
                      <span>Color: {productColor}</span>
                      <ul className="colorPans">
                        {colorList.map((e, i) => {
                          return (
                            <li
                              className={
                                e.AttributeValueName === productColor
                                  ? "selected"
                                  : ""
                              }
                              onClick={() => getColor(e.AttributeValueName)}
                            >
                              <span>{e.AttributeValueName}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {size.length > 0 && (
                <div className="row mb-20">
                  <div className="col-12 col-md-12">
                    <div className="attr-item">
                      <span>Size: {productSize}</span>
                      <ul className="sizePans">
                        {size.map((s) => {
                          return (
                            <li
                              className={
                                s.Sizes.AttributeValueName === productSize &&
                                parseInt(s.variationId) ===
                                  parseInt(variation.ProductVariationId)
                                  ? "selected"
                                  : ""
                              }
                              onClick={() =>
                                getVariation(
                                  s.Sizes.AttributeValueName,
                                  s.variationId,
                                  s.LowStockThreshold
                                )
                              }
                            >
                              <span>{s.Sizes.AttributeValueName}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
                           <div className="row mb-50">
                {parseFloat(productSale) > 0 ? (
                  <>
                    <div className="col-8 col-md-6">
                      <label className="oldPrice">
                        Was: <strike>${productPrice.toLocaleString()}</strike>
                      </label>
                      <span className="discount">
                        -{productSale.toFixed(2)}%
                      </span>
                    </div>
                    <div className="col-4 col-md-6 text-end">
                      <label className="price">
                        ${productSale.toLocaleString()}
                      </label>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-12 col-md-12 text-end">
                      <label className="price">
                        ${productPrice.toLocaleString()}
                      </label>
                    </div>
                  </>
                )}
              </div>
              <div className="row">
                <div className="col-8 col-md-10">
                  <button className="cartBtn" onClick={() => AddToCart()}>
                    Add to Cart{" "}
                    <span className="float-end">
                      <AiOutlinePlusCircle />
                    </span>
                  </button>
                </div>
                <div className="col-4 col-md-2">
                  <span className="quantity">Qty: </span>
                  <input
                    className="quantityBox"
                    type="number"
                    min="1"
                    placeholder="1"
                    value={variation.Quantity}
                    onChange={(e) => handleQuantityChange(e.target.value)}
                  />
                </div>
              </div>
 
            </div>
          </div>
        </div>
      </div>
      <div className="container lowStock mb-50">
        <div className="row titleBar mt-0">
          <div className="col-8 col-md-10">
            <h3>
              <span>You May</span> Also Like
            </h3>
          </div>
          <div className="col-4 col-md-2 text-right">
            <Link to="/">
              View All
              <span>
                <img src={rightRedArrow} alt="Right Arrow" />
              </span>
            </Link>
          </div>
        </div>
        <div className="row tabSection">
          <Swiper
            className="productsSlider"
            spaceBetween={50}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              500: {
                slidesPerView: 1,
              },
              600: {
                slidesPerView: 2,
              },
              800: {
                slidesPerView: 2,
              },
              1100: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 3,
              },
              1300: {
                slidesPerView: 4,
              },
            }}
          >
            {featuredProducts.map((product) => {
              return (
                <SwiperSlide>
                  <div className="item" key={product.ProductId}>
                    <div className="imgBox">
                      <Link
                        to={
                          "/DetailedProduct/" +
                          product.ProductName.replace(/ /g, "-") +
                          "/" +
                          product.ProductId
                        }
                      >
                        <img
                          src={
                            Global.PHOTO_URL + product.DefaultVariation.Image1
                          }
                          alt={product.ProductName}
                        />
                      </Link>
                    </div>
                    <button className="btnWishList">
                      <img src={wishListIcon} alt="WishList"></img>
                      <img
                        src={activeWishListIcon}
                        alt="In WishList"
                        style={{ display: "none" }}
                      ></img>
                    </button>
                    <div className="content">
                      <Link to={"/DetailedProduct/" + product.ProductId}>
                        <h5>{product.ProductName}</h5>
                      </Link>
                      <p className="sub">Playmegastore</p>
                      {product.DefaultVariation.SalePrice === 0 ? (
                        <p className="pricenormal">
                          ${product.DefaultVariation.RegularPrice.toLocaleString()}{" "}
                          <button>
                            <img src={addcart} alt="Add to Cart"></img>
                          </button>
                        </p>
                      ) : (
                        <p className="price">
                          <span className="off">
                            -
                            {product.DefaultVariation.Percentage.toFixed(2) +
                              "%"}
                          </span>
                          <span className="was">
                            <span className="fw-medium">Was:</span>{" "}
                            <strike>
                              ${product.DefaultVariation.RegularPrice.toLocaleString()}
                            </strike>
                          </span>
                          ${product.DefaultVariation.SalePrice.toLocaleString()}{" "}
                          <button>
                            <img src={addcart} alt="Add to Cart"></img>
                          </button>
                        </p>
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      <div className="container lowStock">
        <div className="row titleBar mt-0">
          <div className="col-8 col-md-10">
            <h3>
              <span>Explore </span> More
            </h3>
          </div>
          <div className="col-4 col-md-2 text-right">
            <Link to="/">
              View All
              <span>
                <img src={rightRedArrow} alt="Right Arrow" />
              </span>
            </Link>
          </div>
        </div>
        <div className="row tabSection">
          <Swiper
            className="productsSlider"
            spaceBetween={50}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              500: {
                slidesPerView: 1,
              },
              600: {
                slidesPerView: 2,
              },
              800: {
                slidesPerView: 2,
              },
              1100: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 3,
              },
              1300: {
                slidesPerView: 4,
              },
            }}
          >
            {featuredUpSellProducts.map((product) => {
              return (
                <SwiperSlide>
                  <div className="item" key={product.ProductId}>
                    <div className="imgBox">
                      <Link
                        to={
                          "/DetailedProduct/" +
                          product.ProductName.replace(/ /g, "-") +
                          "/" +
                          product.ProductId
                        }
                      >
                        <img
                          src={
                            Global.PHOTO_URL + product.DefaultVariation.Image1
                          }
                          alt={product.ProductName}
                        />
                      </Link>
                    </div>
                    <button className="btnWishList">
                      <img src={wishListIcon} alt="WishList"></img>
                      <img
                        src={activeWishListIcon}
                        alt="In WishList"
                        style={{ display: "none" }}
                      ></img>
                    </button>
                    <div className="content">
                      <Link to={"/DetailedProduct/" + product.ProductId}>
                        <h5>{product.ProductName}</h5>
                      </Link>
                      <p className="sub">Playmegastore</p>
                      {product.DefaultVariation.SalePrice === 0 ? (
                        <p className="pricenormal">
                          $
                          {product.DefaultVariation.RegularPrice.toLocaleString()}{" "}
                          <button>
                            <img src={addcart} alt="Add to Cart"></img>
                          </button>
                        </p>
                      ) : (
                        <p className="price">
                          <span className="off">
                            -
                            {product.DefaultVariation.Percentage.toFixed(2) +
                              "%"}
                          </span>
                          <span className="was">
                            <span className="fw-medium">Was:</span>{" "}
                            <strike>
                              $
                              {product.DefaultVariation.RegularPrice.toLocaleString()}
                            </strike>
                          </span>
                          ${product.DefaultVariation.SalePrice.toLocaleString()}{" "}
                          <button>
                            <img src={addcart} alt="Add to Cart"></img>
                          </button>
                        </p>
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};
export default DetailedProduct;
