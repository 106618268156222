import React, { useEffect } from "react";
import product from "../../../images/products/product9.png";
import { FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import Util from "../../../Classes/Util";
import { Global } from "../../../Variables/Global";
import { useState } from "react";
import { toast } from "react-toastify";

const Cart = (props) => {
  const [cartItemsList, setCartItemsList] = useState([]);
  const GetCartItems = () => {
  var cart = ""
  if(sessionStorage.getItem("GuestCart") == null){
    cart = sessionStorage.getItem("Cart");
  }
  else{
    cart = sessionStorage.getItem("GuestCart");
  }

  debugger;
    var cartId = Util.decrypt(cart);
    var axios = require("axios");

    var config = {
      method: "get",
      url: Global.API_URL + "Cart/GetCartItems/" + cartId + "/-150/1/125",
    };

    axios(config)
      .then(function (response) {
        if (response.status == "200") {
          setTimeout(() => {
            setCartItemsList(response.data.CartItems);
          }, 300);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const RemoveCartItem = (productVariationId) => {
    var axios = require("axios");
    var cartId = Util.decrypt(sessionStorage.getItem("GuestCart"));
    var config = {
      method: "delete",
      url:
        Global.API_URL +
        "Cart/RemoveItemFromCart/" +
        productVariationId +
        "/" +
        cartId,
    };

    axios(config)
      .then(function (response) {
        if (response.status == "200") {
          toast.success("Item Removed From Cart");
          GetCartItems();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    props.setHeaderTitle("Cart");
    props.setFooterActive(3);
    GetCartItems();
  }, []);

  return (
    <>
      <div className="container Cart">
        <div className="row d-none d-md-block">
          <div className="col-12 col-md-12">
            <ul className="bread-crumb">
              <li>Home</li>
              <li>/</li>
              <li>Cart</li>
            </ul>
          </div>
        </div>
        <div className="row d-none d-md-block">
          <div className="col-12 col-md-12">
            <h1>Cart</h1>
          </div>
        </div>
        {/* {cartItemsList.length > 0 ? ( */}
        <div className="row topMargin">
          <div className="col-12 col-lg-9">
            <div className="infoBox position-relative shadowBoxMobile">
              {cartItemsList.length > 0 &&
                cartItemsList.map((p) => {
                  return (
                    <>
                      <div className="row p-relative">
                        <div className="col-3 mb-5">
                          <img
                            src={Global.PHOTO_URL + p.Image}
                            className="cartImg"
                          />
                        </div>
                        <div className="col-9 m-auto ">
                          <div className="row">
                            <div className="col-8">
                              <label className="itemName">
                                {p.ProductName}
                              </label>
                              <br />
                              <p
                                className="itemDesc"
                                dangerouslySetInnerHTML={{
                                  __html: p.Description,
                                }}
                              ></p>
                              <p>
                                {p.SalePrice > 0 ? (
                                  <>
                                    <span className="off">-15%</span>
                                    <span className="old">
                                      Was:{" "}
                                      <strike>
                                        {" "}
                                        ${p.RegularPrice.toLocaleString()}
                                      </strike>
                                    </span>
                                    <span className="sale">
                                      ${p.SalePrice.toLocaleString()}
                                    </span>
                                  </>
                                ) : (
                                  <span className="sale">
                                    ${p.RegularPrice.toLocaleString()}
                                  </span>
                                )}
                              </p>
                            </div>
                            <div className="col-4 text-end mt-25">
                              <span className="quantity">Qty: </span>
                              <input
                                className="quantityBox"
                                type="number"
                                min="1"
                                placeholder="1"
                              />
                              <br />
                              <span className="sale">
                                $
                                {p.SalePrice > 0
                                  ? p.SalePrice.toLocaleString()
                                  : p.RegularPrice.toLocaleString()}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <FaTrashAlt
                                className="deleteBtn"
                                onClick={() =>
                                  RemoveCartItem(p.ProductVariationId)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <hr></hr>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <div className="infoBox ">
              <div className="row">
                <div className="col-8">
                  <label className="sideTitlep">1 iPhone X Repair</label>
                </div>
                <div className="col-4 text-end">
                  <label className="price">$ 60.00</label>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <label className="sideTitlep">Delivery</label>
                </div>
                <div className="col-4 text-end">
                  <label className="price">$ 2.00</label>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-8">
                  <label className="sideTitlep red">Total</label>
                </div>
                <div className="col-4 text-end">
                  <label className="price">$ 62.00</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Link to="/Checkout" type="button" className="proceedBtn">
                  proceed to checkout
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Link to="/" type="button" className="continueBtn">
                  continue Shopping
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Cart;
