import React, { useState } from "react";
import { toast } from "react-toastify";
import Util from "../../../Classes/Util";
import { Global } from "../../../Variables/Global";
import { useNavigate } from "react-router-dom";

const EmailConfirmation = (e) => {
  const [code, setCode] = useState("");
  const navigate = useNavigate();

  function handleSubmit(e) {
    debugger;
    var number = localStorage.getItem("Number");

    var ID = Util.decrypt(number);
    fetch(Global.API_URL + "Customer/EmailConfirmation/" + ID + "/-150", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
      body: JSON.stringify({
        code: code,
      }),
    })
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
        return Response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((Result) => {
        toast.success("Email SuccessFully Confirmed, please login again");
        navigate("/Profile");
      })
      .catch((error) => {
        if (error.message.replaceAll('"', "") === "Please Confirm Your Email") {
        }
        toast.error(error.message.replaceAll('"', ""));
      });
  }

  const resendCode = () => {
    var axios = require("axios");
    var config = {
      method: "POST",
      url: Global.API_URL + "Customer/ResendConfirmationCode/1/-150",
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className="container mt-60">
        <div className="row">
          <div className="col-12 col-md-12">
            <ul className="bread-crumb">
              <li>Home</li>
              <li>/</li>
              <li>Profile</li>
            </ul>
          </div>
        </div>
        <form
          className="row justify-content-center"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="col-12 col-md-8">
            <div className="authenticationCard">
              <div className="row topBar">
                <div className="col-12 text-center">
                  <button className="active">Email Confirmation</button>
                </div>
              </div>
              <div className="row mb-35">
                <div className="col-12 col-md-2">
                  <label>Code:</label>
                </div>
                <div className="col-12 col-md-10">
                  <input
                    className="form-control"
                    placeholder="Type Here"
                    required
                    onChange={(e) => setCode(e.target.value)}
                  ></input>
                  <span onClick={() => resendCode()}>Resend Code</span>
                </div>
              </div>
              <div className="row mb-35 submit">
                <div className="col-12 text-center">
                  <button onClick={(e) => handleSubmit(e)}> Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EmailConfirmation;
