
import logo from "../../images/logo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import wishList from "../../images/icons/heart.png";
import cart from "../../images/icons/cart.png";
import profile from "../../images/icons/user.png";
import search from "../../images/icons/search.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { MdOutlineNotifications } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import { FiSliders } from "react-icons/fi";

const Header = (props) => {
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("PMToken") === null) {
      setIsLogged(false);
    } else {
      setIsLogged(true);
    }
  }, [props]);
  return (
    <header>
      <div className="container d-none d-md-block desktopHeader">
        <div className="row">
          <div className="col-md-2 col-lg-2">
            <Link to={"/"}>
              <img src={logo} alt="Play Mega Store Logo" />
            </Link>
          </div>
          <div className="col-md-6 col-lg-8">
            <Navbar expand="lg">
              <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link href="/Category/Computers/1">Computers</Nav.Link>
                    <Nav.Link href="/Category/Phones-Tables/2">Phones & Tablets</Nav.Link>
                    <Nav.Link href="/Category/Games/3">Games & Consules</Nav.Link>
                    <Nav.Link href="/Category/Gadgets/4">Gadgets</Nav.Link>
                    <Nav.Link href="/Category/Accessories/5">Accessories</Nav.Link>
                    <Nav.Link href="/Services">Services</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
          <div className="col-md-4 col-lg-2 text-lg-end">
            <ul className="socialMedia">
              <li>
                <Link to={"/WishList"}>
                  <img src={wishList} alt="Wish List" />
                </Link>
              </li>
              <li>
                <Link to={"/Cart"}>
                  <img src={cart} alt="Cart" />
                </Link>
              </li>
              <li>
                <Link to={isLogged ? "/Profile" : "/UserAuthentication"}>
                  <img src={profile} alt="Profile" />
                </Link>
              </li>
              <li>
                <a href="#">
                  <img src={search} alt="Search" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="conatiner d-md-none mobileHeader">
        <div className="row">
          <div className="col-9">
            <label className="title">{props.headerTitle}</label>
          </div>
          <div className="col-3 text-end m-auto position-relative">
            <MdOutlineNotifications className="bell" />
            <div className="dot">.</div>
          </div>
        </div>
        <div className="row">
          <div className="col-10 position-relative">
            <BsSearch className="search"/>
            <input className="inputBox" placeholder="Search Here" type="text"/>
          </div>
          <div className="col-2 ps-0">
            <button className="searchBtn"><FiSliders/></button>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header