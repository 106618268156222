import { BrowserRouter, Routes, Route } from "react-router-dom";
import UserAuthentication from "../pages/Customers/Authentication/UserAuthentication";
import ForgetPassword from "../pages/Customers/Authentication/ForgetPassword";
import ResetPassword from "../pages/Customers/Authentication/ResetPassword";
import Home from "../pages/Home/Home";
import Profile from "../pages/Profile/Profile";
import Cart from "../pages/Customers/Cart/Cart";
import Checkout from "../pages/Customers/Cart/Checkout";
import OrderTracking from "../pages/Customers/Cart/OrderTracking";
import WishList from "../pages/Customers/WishList/WishList";
import DetailedProduct from "../pages/Products/DetailedProduct";
import ListProducts from "../pages/Products/ListProducts";
import Services from "../pages/Services/Service";
import ServiceDetails from "../pages/Services/ServiceDetails";
import DealBundles from "../pages/Products/DealBundles";
import Returns from "../pages/Settings/Returns";
import FAQs from "../pages/Settings/FAQs";
import CustomerService from "../pages/Settings/CustomerService";
import EmailConfirmation from "../pages/Customers/Authentication/EmailConfirmation";
import CategoryList from "../pages/Products/CategoryList";

const AppRoute = (props) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home
          wishListCount={props.wishListCount}
          cartCount={props.cartCount}
          setCartCount={props.setCartCount}
          setWishListCount={props.setWishListCount}
          cartItem={props.cartItem}
          setCartItem={props.setCartItem}
          setWishListItem={props.setWishListItem}
          wishListItem={props.wishListItem}
          headerTitle = {props.headerTitle}
          setHeaderTitle = {props.setHeaderTitle}
          footerActive = {props.footerActive}
          setFooterActive = {props.setFooterActive}
        />}></Route>
        <Route path="/UserAuthentication" element={<UserAuthentication
          customerDetail={props.customerDetail}
          setCustomerDetail={props.setCustomerDetail}
          subscribe={props.subscribe}
          setSubscribe={props.setSubscribe}
          headerTitle = {props.headerTitle}
          setHeaderTitle = {props.setHeaderTitle}
          footerActive = {props.footerActive}
          setFooterActive = {props.setFooterActive}
        />}></Route>
        <Route path="/Forget-Password" element={<ForgetPassword
         headerTitle = {props.headerTitle}
         setHeaderTitle = {props.setHeaderTitle}
         footerActive = {props.footerActive}
         setFooterActive = {props.setFooterActive} />}></Route>
        <Route path="/Email-Confirmation" element={<EmailConfirmation
         headerTitle = {props.headerTitle}
         setHeaderTitle = {props.setHeaderTitle}
         footerActive = {props.footerActive}
         setFooterActive = {props.setFooterActive} />}></Route>
        <Route path="/Reset-Password" element={<ResetPassword
          customerDetail={props.customerDetail}
          setCustomerDetail={props.setCustomerDetail}
          subscribe={props.subscribe}
          setSubscribe={props.setSubscribe}
          headerTitle = {props.headerTitle}
          setHeaderTitle = {props.setHeaderTitle}
          footerActive = {props.footerActive}
          setFooterActive = {props.setFooterActive}
        />}></Route>
        <Route path="/Services" element={<Services
         headerTitle = {props.headerTitle}
         setHeaderTitle = {props.setHeaderTitle}
         footerActive = {props.footerActive}
         setFooterActive = {props.setFooterActive} />}></Route>
        <Route path="/Profile" element={<Profile
         headerTitle = {props.headerTitle}
         setHeaderTitle = {props.setHeaderTitle}
         footerActive = {props.footerActive}
         setFooterActive = {props.setFooterActive} />}></Route>

        <Route path="/WishList" element={<WishList
         headerTitle = {props.headerTitle}
         setHeaderTitle = {props.setHeaderTitle}
         footerActive = {props.footerActive}
         setFooterActive = {props.setFooterActive} />}></Route>
        <Route path="/Cart" element={<Cart
         headerTitle = {props.headerTitle}
         setHeaderTitle = {props.setHeaderTitle}
         footerActive = {props.footerActive}
         setFooterActive = {props.setFooterActive} />}></Route>
        <Route path="/Checkout" element={<Checkout
         headerTitle = {props.headerTitle}
         setHeaderTitle = {props.setHeaderTitle}
         footerActive = {props.footerActive}
         setFooterActive = {props.setFooterActive} />}></Route>
        <Route path="/Order-Tracking" element={<OrderTracking
         headerTitle = {props.headerTitle}
         setHeaderTitle = {props.setHeaderTitle}
         footerActive = {props.footerActive}
         setFooterActive = {props.setFooterActive} />}></Route>
        <Route path="/Deal-Bundles" element={<DealBundles
         headerTitle = {props.headerTitle}
         setHeaderTitle = {props.setHeaderTitle}
         footerActive = {props.footerActive}
         setFooterActive = {props.setFooterActive} />}></Route>
        <Route path="/Returns" element={<Returns
         headerTitle = {props.headerTitle}
         setHeaderTitle = {props.setHeaderTitle}
         footerActive = {props.footerActive}
         setFooterActive = {props.setFooterActive} />}></Route>
        <Route path="/FAQs" element={<FAQs
         headerTitle = {props.headerTitle}
         setHeaderTitle = {props.setHeaderTitle}
         footerActive = {props.footerActive}
         setFooterActive = {props.setFooterActive} />}></Route>
        <Route path="/Customer-Service" element={<CustomerService
         headerTitle = {props.headerTitle}
         setHeaderTitle = {props.setHeaderTitle}
         footerActive = {props.footerActive}
         setFooterActive = {props.setFooterActive} />}></Route>

        <Route path="/Category/:CategoryName/:CategoryId" element={<ListProducts
         headerTitle = {props.headerTitle}
         setHeaderTitle = {props.setHeaderTitle}
         footerActive = {props.footerActive}
         setFooterActive = {props.setFooterActive} />}></Route>
        <Route path="/DetailedProduct/:ProductName/:ProductId" element={<DetailedProduct
         headerTitle = {props.headerTitle}
         setHeaderTitle = {props.setHeaderTitle}
         footerActive = {props.footerActive}
         setFooterActive = {props.setFooterActive} />}></Route>
        <Route path="/ServiceDetails" element={<ServiceDetails
         headerTitle = {props.headerTitle}
         setHeaderTitle = {props.setHeaderTitle}
         footerActive = {props.footerActive}
         setFooterActive = {props.setFooterActive} />}></Route>
        <Route path="/Category-List" element={<CategoryList
         headerTitle = {props.headerTitle}
         setHeaderTitle = {props.setHeaderTitle}
         footerActive = {props.footerActive}
         setFooterActive = {props.setFooterActive} />}></Route>
      </Routes>

    </>
  );
};
export default AppRoute;
